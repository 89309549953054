import React, { useState, useEffect } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ApiService from '../../service/ApiService';
import { apiPaths } from '../../service/apiPaths';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string); // Replace with your Stripe publishable key

const CheckoutForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentResult, setPaymentResult] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setPaymentResult('Card element not found');
      setLoading(false);
      return;
    }

    try {
      const response = await ApiService({
        method: 'POST',
        endpoint: apiPaths.paymentIntent,
        data: JSON.stringify({
          amount: 2000, 
          currency: 'EUR',
        }),
      });
      console.log(response)
      const clientSecret = response.response.clientSecret
      console.log(clientSecret)
 
      

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement, 
        },
      });

      console.log(result)

      if (result.error) {
        setPaymentResult(`Payment failed: ${result.error.message}`);
      } else if (result.paymentIntent?.status === 'succeeded') {
        setPaymentResult('Payment successful!');
      }

    } catch (error) {
      setPaymentResult(`Error: `);
    }

    setLoading(false);
  };

  return (
    <div className="max-w-md mx-auto p-6 border border-gray-300 rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-6 text-center">Complete Your Payment</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="border p-4 rounded-lg bg-gray-50">
          <label htmlFor="card-element" className="block mb-2 text-sm font-medium text-gray-700">
            Card Information
          </label>
          <CardElement id="card-element" className="border p-2 rounded-md" />
        </div>
        <button
          type="submit"
          disabled={!stripe || loading}
          className="w-full py-2 bg-blue-500 text-white font-semibold rounded-md disabled:bg-blue-300"
        >
          {loading ? 'Processing...' : 'Pay Now'}
        </button>
      </form>
      {paymentResult && <p className="mt-4 text-center">{paymentResult}</p>}
    </div>
  );
};

const StripePayment: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default StripePayment;
