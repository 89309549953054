
// src/hooks/useLogin.ts
import { useState } from "react";
import ApiService from "../../service/ApiService";
import { apiPaths } from "../../service/apiPaths";
import Cookies from 'js-cookie'; 

export const useProjectManager = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const becomeProjectManager = async (
    data: any
  ): Promise<any> => {
    setLoading(true);
    setError(null);


    try {
       
      const response = await ApiService({
        method: "POST",
        endpoint: apiPaths.becomeProjectManager, 
        data: data, 
        headers: {
          "Content-Type": "application/json",
        },
      });
      

      // Store the auth token in cookies after successful login
    //   if (response?.token) {
    //     Cookies.set('authToken', response.token, { expires: 7, path: '' });
    //   }\
    
    console.log(response)

      return response;
    } catch (error: any) {
      const errorMessage = error.message || "Failed to login";
      setError(errorMessage);
      console.error("Login error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getProjectManagerApplications = async()=>{
    try {
       
        const response = await ApiService({
          method: "GET",
          endpoint: apiPaths.becomeProjectManager, 
          headers: {
            "Content-Type": "application/json",
          },
        });
        
  
        // Store the auth token in cookies after successful login
      //   if (response?.token) {
      //     Cookies.set('authToken', response.token, { expires: 7, path: '' });
      //   }\
      
      console.log(response)
  
        return response;
      } catch (error: any) {
        const errorMessage = error.message || "Failed to login";
        setError(errorMessage);
        console.error("Login error:", error);
        return null;
      } finally {
        setLoading(false);
      }

  }

  return { becomeProjectManager,getProjectManagerApplications, loading, error };
};
