const formatDate = (dateString: string): string => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec'
    ];
  
    const date = new Date(dateString); // Convert MongoDB date string to Date object
  
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
  
    return `${day} ${month} ${year} | ${formattedHour}:${formattedMinute} ${ampm}`;
  };
  
  export default formatDate;
