import React, { useState } from "react";
import UpIcon from '../../../images/Help/up.png';
import DownIcon from '../../../images/Help/down.png';

interface FAQItem {
  question: string;
  answer: string;
}

const FAQ: React.FC = () => {
  const [selected, setSelected] = useState<number | null>(null);

  const toggle = (i: number) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const faqs :FAQItem[]= [
    {
      question: "What is our mission for environmental sustainability?",
      answer:
        "Our platform connects investors with projects that focus on renewable energy, carbon reduction, and other sustainable initiatives, aiming to create a positive impact on the environment."
    },
    {
      question: "How can I upload a project related to environmental benefits?",
      answer:
        "To upload a project, navigate to the 'Submit Project' section in your dashboard, fill in the required details, and provide documentation showcasing its environmental impact. Our team will review and approve eligible projects."
    },
    {
      question: "What types of projects can I invest in?",
      answer:
        "You can invest in a wide range of projects including renewable energy solutions, eco-friendly technology, carbon offset programs, water conservation projects, and more."
    },
    {
      question: "Is there a minimum investment amount required?",
      answer:
        "Yes, the minimum investment amount varies depending on the project. You can find specific details on each project’s page under the 'Investment Details' section."
    },
    {
      question: "How do I track the performance of my investments?",
      answer:
        "You can track your investments through the 'Investments' tab in your dashboard. It provides real-time updates on project progress, financial performance, and environmental impact reports."
    },
    {
      question: "Are there any risks associated with these projects?",
      answer:
        "As with any investment, there are risks involved. Our platform thoroughly vets projects to minimize risks, but we recommend reviewing project details and consulting with a financial advisor if needed."
    },
    {
      question: "How does the platform ensure project transparency?",
      answer:
        "We require all project owners to provide regular updates, environmental impact reports, and financial statements. These are available to investors for review in the project details section."
    },
    {
      question: "Can I withdraw my investment before project completion?",
      answer:
        "Withdrawal policies depend on the specific project. Some projects may allow early withdrawal with certain conditions, while others may require the investment to remain until project completion."
    },
  ];
  

  return (
    <div className="p-6 bg-[#fff] rounded-[16px]">
      <h1 className="text-[22px] font-semibold">Help and Support</h1>
      <p className="text-[14px] text-[#9e9b9b]">
        Need assistance? Visit our Help & Support page for FAQs, troubleshooting guides, and direct access to customer support. We're here to ensure your experience is smooth and hassle-free.
      </p>
      <div className="border p-4 mt-4 rounded-[16px]">
        <div className="faq-section">
          <h2 className="text-[#52bbab] font-semibold text-[18px] mb-6">
            Frequently Asked Questions
          </h2>
          <div className="faq-container">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item bg-[#f6f6f6] mb-4 px-4 py-6 rounded-[12px]">
                <div
                  className={`faq-question flex justify-between items-center ${selected === index ? "active" : ""}`}
                  onClick={() => toggle(index)}
                >
                  <h3 className="font-medium">{faq.question}</h3>
                  <p className="rounded-full bg-[#e9e9e9] h-[40px] w-[40px] flex items-center justify-center border">
                    {selected === index ? (
                      <img className="w-[20px] h-[9px]" src={UpIcon} alt="" />
                    ) : (
                      <img className="w-[20px] h-[9px]" src={DownIcon} alt="" />
                    )}
                  </p>
                </div>
                {selected === index && <p className="faq-answer mt-4">{faq.answer}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
