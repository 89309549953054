import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice"
import signupSlice from "./signupSlice"
import loaderSlice from "./loaderSlice"


const appStore = configureStore({
    reducer:{
        user:userSlice,
        signup:signupSlice,
        loader:loaderSlice,
    }
});

export type RootState = ReturnType<typeof appStore.getState>;
export default appStore;