import { useSignup } from "./useSignup";
import { useDispatch, useSelector } from "react-redux"; // Fixed import names
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import signupImage from "../../../images/login/signup.png";
import facebook from "../../../images/login/facebook.png";
import google from "../../../images/login/google.png";
import apple from "../../../images/login/apple.png";
import LoginLogo from "../../../images/login/loginLogo.png";
import { setUserDetails } from "../../../redux/signupSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface FormData {
  fullName: string;
  email: string;
  phoneNumber: string;
}

interface ValidationError {
  field: keyof FormData | "submit";
  message: string;
}

const Signup = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("+1"); // Default to USA code


  const {sendOtp} = useSignup()
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    phoneNumber: "",
  });


// Toggle password visibility
// const togglePasswordVisibility = () => {
//   setShowPassword(!showPassword);
// };

// const toggleConfirmPasswordVisibility = () => {
//   setShowConfirmPassword(!showConfirmPassword);
// };


  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const isValidName = (name: string): boolean => {
    const nameRegex = /^[A-Za-z][A-Za-z\s'-]{0,48}[A-Za-z]$/;
    return nameRegex.test(name);
  };

  const isValidPhoneNumber = (phone: string): boolean => {
    const phoneRegex = /^[0-9]{10}$/; // Simple validation for 10-digit numbers
    return phoneRegex.test(phone);
  };

  const isValidPassword = (password: string): boolean => {
    // Password must contain:
    // - At least 8 characters
    // - At least one uppercase letter
    // - At least one lowercase letter
    // - At least one number
    // - At least one special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const doPasswordsMatch = (password: string, confirmPassword: string): boolean => {
    return password === confirmPassword;
  };

  const validateForm = (data: FormData): { isValid: boolean; errors: ValidationError[] } => {
    const validationErrors: ValidationError[] = [];
    let alertMessage = "";

    if (!data.fullName.trim()) {
      validationErrors.push({ field: "fullName", message: "Full name is required" });
      alertMessage = "Please enter your full name";
    } else if (!isValidName(data.fullName.trim())) {
      validationErrors.push({
        field: "fullName",
        message: "Please enter a valid name (letters, spaces, hyphens, and apostrophes only)",
      });
      alertMessage = "Invalid name format";
    }

    if (!data.email.trim()) {
      validationErrors.push({ field: "email", message: "Email is required" });
      alertMessage = alertMessage || "Please enter your email";
    } else if (!isValidEmail(data.email.trim())) {
      validationErrors.push({ field: "email", message: "Please enter a valid email address" });
      alertMessage = alertMessage || "Invalid email format";
    }

    if (!data.phoneNumber.trim()) {
      validationErrors.push({ field: "phoneNumber", message: "Phone number is required" });
      alertMessage = alertMessage || "Please enter your phone number";
    } else if (!isValidPhoneNumber(data.phoneNumber.trim())) {
      validationErrors.push({ field: "phoneNumber", message: "Please enter a valid phone number" });
      alertMessage = alertMessage || "Invalid phone number format";
    }

    if (validationErrors.length > 0) {
      toast(alertMessage);
    }

    return {
      isValid: validationErrors.length === 0,
      errors: validationErrors,
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const trimmedFormData: FormData = {
      fullName: formData.fullName.trim(),
      email: formData.email.trim(),
      phoneNumber: formData.phoneNumber.trim(),
    };

    const { isValid, errors: validationErrors } = validateForm(trimmedFormData);

    if (!isValid) {
      setErrors(validationErrors);
      return;
    }

    try {
      dispatch(setUserDetails(trimmedFormData));
      const data = await sendOtp(trimmedFormData.email)
      console.log(data)
      if(data.success){
        toast("OTP Sent Successfully");
        navigate("/verify/signup")
      }else{
        toast(data?.message);
      }
    } catch (error) {
      const errorMessage = "Failed to create account. Please try again.";
      setErrors([{ field: "submit", message: errorMessage }]);
      toast.error(errorMessage);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
    setErrors((prev) => prev.filter((error) => error.field !== id));
  };

  
  const getErrorMessage = (field: keyof FormData | 'submit') => {
    const error = errors.find(e => e.field === field);
    return error ? error.message : '';
  };

  return (
    <div className="min-h-screen flex items-center justify-center relative pb-10 pt-20">
      <img
        className="absolute lg:top-7 lg:right-7 top-5 left-auto right-auto w-[210px]"
        src={LoginLogo}
        alt="Logo"
      />
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6 flex items-center justify-center lg:px-10">
            <img className="lg:h-[100vh] max-w-full" src={signupImage} alt="Signup" />
          </div>
          <div className="col-span-12 lg:col-span-6 flex items-center lg:px-10 lg:mt-0 mt-5">
            <div className="form-box w-full lg:px-10">
              <h3 className="login-heading mb-3">Sign up</h3>
              <p className="login-subText">
                Let’s get you all set up so you can access your account.
              </p>

              <form className="mx-auto mt-7" onSubmit={handleSubmit}>
                <div className="relative mb-5">
                  <input
                    type="text"
                    id="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none peer"
                    placeholder="Full Name"
                    required
                  />
                  <label
                    htmlFor="fullName"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Full Name
                  </label>
                </div>
                <div className="relative mb-5">
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none"
                    placeholder="Email"
                    required
                  />
                  <label
                    htmlFor="email"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Email
                  </label>
                </div>

                <div className="relative mb-5">
                  <div className="flex border ">
                    <select
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      className="border rounded-l-[4px] px-2 py-2 text-sm focus:outline-none border-b-black border-t-black border-l-black"
                    >
                      <option value="+1">USA (+1)</option>
                      <option value="+91">India (+91)</option>
                      <option value="+49">Germany (+49)</option>
                    </select>
                    <input
                      type="tel"
                      id="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-r-[4px] border border-black appearance-none focus:outline-none"
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                </div>

                {/* Password field with visibility toggle */}
                 {/* <div className="relative mb-5">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none"
                    placeholder="Password"
                    required
                  />
                  <label
                    htmlFor="password"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Password
                  </label>
                  <span
                    className="absolute right-2 top-4 cursor-pointer text-sm"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                  </span>
                </div> */}

              
               {/* <div className="relative mb-5">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none"
                    placeholder="Confirm Password"
                    required
                  />
                  <label
                    htmlFor="confirmPassword"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Confirm Password
                  </label>
                  <span
                    className="absolute right-2 top-4 cursor-pointer text-sm"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                  </span>
                </div>   */}

                <div className="flex items-start mb-5">
                  <div className="flex items-center w-full justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                        required
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900">
                        I agree to all the{" "}
                        <span className="text-[var(--primary-color)]">Terms</span> and{" "}
                        <span className="text-[var(--primary-color)]">Privacy Policies</span>
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="text-white min-w-full bg-[var(--primary-color)] focus:outline-none font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center"
                onClick={handleSubmit} >
                  Send Otp
                </button>
              </form>
            <Link to="/login" >
              <p className="text-center mt-5">
                Already have an account?
                <span className="text-[var(--primary-color)] cursor-pointer"> Login</span>
              </p>
              </Link>

              <p className="two-side-line mt-7">Or login with</p>
              <div className="grid grid-cols-12 mt-4">
                <div className="col-span-4 px-2">
                  <button className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full">
                    <img width={24} src={facebook} alt="Facebook" />
                  </button>
                </div>
                <div className="col-span-4 px-2">
                  <button className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full">
                    <img width={24} src={google} alt="Google" />
                  </button>
                </div>
                <div className="col-span-4 px-2">
                  <button className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full">
                    <img width={24} src={apple} alt="Apple" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
