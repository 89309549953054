import { useState } from "react";
import { FileUploadResponse, ProfileFormData, ProfileResponse } from "./Profile.props";
import ApiService from "../../service/ApiService";
import { apiPaths } from "../../service/apiPaths";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../redux/loaderSlice";
import qs from 'qs'; // Use qs to format the data for URL encoding


export const useProfile=()=>{
  const dispatch = useDispatch()
    // const API_BASE_URL = "http://192.168.1.21:81"
    const API_BASE_URL = "http://localhost:81"

    const getProfile=async (): Promise<ProfileResponse | null> => {
      // dispatch(setIsLoading(true))
    
        try {
          const response = await ApiService({
            method: "GET",
            endpoint: apiPaths.Profile,
          });
    
          if (response) {
            // dispatch(setIsLoading(false))
            return response.response;
            
          }
        } catch (error: any) {
          const errorMessage = error.message || "Failed to Get Profile";
          toast.error(errorMessage);
          // dispatch(setIsLoading(false))
          console.error("Profile error while Fetching:", error);
        } finally {
          // dispatch(setIsLoading(false))
        }
        return null;
      };


    const updateProfile=async (data : ProfileFormData): Promise<ProfileResponse | null> => {
      dispatch(setIsLoading(true))

        try {
          const response = await ApiService({
            method: "POST",
            endpoint: apiPaths.Profile,
            data,
          });
    
          if (response) {
            toast.success("Profile Updated successfully!");
            return response.response;
          }
        } catch (error: any) {
          const errorMessage = error.message || "Failed to Update Profile";
          toast.error(errorMessage);
          console.error("Profile error while Updating:", error);
        } finally {
          dispatch(setIsLoading(false))
        }
        return null;
      };

    const updateProfilePic=async(formData:any) : Promise<FileUploadResponse | null>=>{
      dispatch(setIsLoading(true))

      try {
        const response = await axios.post(
          API_BASE_URL +"/"+ apiPaths.imageUpload,
          formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response){
          console.log("Response from updateProfilePic : ",response)
          return response.data;
        }
        else{
          return null;
        }
      } catch (error) {
        console.error("Error uploading balance sheet:", error);
        return null;
       }finally{
          dispatch(setIsLoading(false))
        }
    }


    const changePassword = async (oldPassword:string, newPassword:string) => {
      try {
          const response = await axios.post(
              "http://localhost:81/" + apiPaths.changePassword,
              qs.stringify({ newPassword: newPassword, oldPassword: oldPassword }), 
              {
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  withCredentials: true, // Include cookies in the request

              }
          );
          return response
      } catch (error) {
          console.error('Error:', error);
          return null
      }
  };

      return {getProfile,updateProfile,updateProfilePic , changePassword}
}