import React, { useEffect, useState } from "react";
import DashIcon from "../../../images/Dashboard/dashIcon.png";
import ButtonIcon from "../../../images/Dashboard/buttonIcon.png";
import { GraphData, ProjectResponse } from "../Dashboard.props";
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import LineChart from "../../../components/Graph/Line";
import { Link } from "react-router-dom";
import { dummyData } from "./dummyData";
import { useExplore } from "../../Explore/useExplore";
import { useDashboard } from "../useDashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/appStore";
import { Wallet } from "../../Settings/Components/Wallet.prop";
import { useManageWallet } from "../../Settings/Components/useManageWallet";

const Dashboard: React.FC = () => {
  const { getProjectsByUser } = useDashboard();
  const [allProjects, setAllProjects] = useState<ProjectResponse[]>([]);
  const user = useSelector((store:RootState)=>store?.user?.user)
  const [page,setPage]=useState<number>(1);
  const [limit,setLimit]=useState<number>(4);
  const [search,setSearch] = useState<string>("");
  const [totalPages,setTotalPages] = useState(1);
  const [searchInput,setSearchInput] = useState<string>("");
  const [isLoading,setIsLoading]=useState(false);
  const { getWallets, addWallet, loading, connectWallet } = useManageWallet();
  const [wallets, setWallets] = useState<Wallet[]>([]);

  useEffect(() => {  
    async function fetchProjects() {
      console.log("USER ID IN DASHBOARD : ",user)

      if(user){
        const response = await getProjectsByUser(String(user?._id),page,limit,search,"investor");
        if (response && Array.isArray(response.docs)) {
          setAllProjects(response.docs);
          setTotalPages(response.totalPages)

          // console.log("NUMBER OF PAGES : ",response)
        } else {
          console.error("Error fetching project details or no projects available.");
        }
      }
      // setIsLoading(false)
    }

    fetchProjects();

  }, [page,search,user]);

  useEffect(() => {
    const fetchWallets = async () => {
      const walletsData: Wallet[] | null = await getWallets();
      if (walletsData) {
        setWallets(walletsData);
      }
    };
    fetchWallets();
  }, []);

  const [graphData, setGraphData] = useState<GraphData>({
    labels: [
      "Jan 2023",
      "Feb 2023",
      "Mar 2023",
      "Apr 2023",
      "May 2023",
      "Jun 2023",
      "Jul 2023",
      "Aug 2023",
      "Sep 2023",
      "Oct 2023",
      "Nov 2023",
      "Dec 2023",
    ],
    datasets: [
      {
        label: "Monthly Patient Count",
        data: [10, 9, 11, 12, 11, 8, 10, 11, 10, 12, 10],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 3,
      },
      {
        label: "Monthly Admission Count",
        data: [6, 8, 7, 7, 6, 5, 8, 9, 6, 7, 6],
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 3,
      },
      {
        label: "Monthly Discharge Count",
        data: [8, 6, 6, 9, 7, 4, 6, 6, 5, 6, 5],
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 3,
      },
    ],
  });

  const chartOptions = {
    scales: {
      x: {
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
      },
      y: {
        grid: {
          display: false, // Disable grid lines on the y-axis
        },
      },
    },
  };

  // if(allProjects.length===0){
  //   setAllProjects(dummyData)
  // }

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <>
      <div className="p-5 bg-[#fff] rounded-[16px]">
        <div>
        <h1 className="font-semibold text-[22px]">Dashboard</h1>
        <p>
          Discover groundbreaking green initiatives transforming the world. Our
          platform enables project owners to list eco-friendly projects on the
          blockchain, while investors can explore, invest, and receive tokens as
          proof of their contribution to a sustainable future. Join the movement
          towards a greener tomorrow by supporting projects that align with your
          values.
        </p>
      </div>
      <div className="flex bg-[#fff] p-6 rounded-[16px] justify-between items-center mt-5">
        <div className="flex bg-[#f4f4f4] gap-4 w-[280px] items-center p-4 rounded-[16px]">
          <div className="h-[40px] w-[40px] flex items-center justify-center bg-[#fa5a7d] rounded-full">
            <img className="h-[24px] w-[24px]" src={DashIcon} alt="" />
          </div>
          <div>
            <p className="text-[#7b7b7b]">Total Investment</p>
            <h6 className="font-semibold mt-1">$2,00,000</h6>
          </div>
        </div>
        <div className="flex bg-[#f4f4f4] gap-4 w-[280px] items-center p-4 rounded-[16px]">
          <div className="h-[40px] w-[40px] flex items-center justify-center bg-[#ff947a] rounded-full">
            <img className="h-[24px] w-[24px]" src={DashIcon} alt="" />
          </div>
          <div>
            <p className="text-[#7b7b7b]">Current Investment</p>
            <h6 className="font-semibold mt-1">$90,000</h6>
          </div>
        </div>
        <div className="flex bg-[#f4f4f4] gap-4 w-[280px] items-center p-4 rounded-[16px]">
          <div className="h-[40px] w-[40px] flex items-center justify-center bg-[#3cd856] rounded-full">
            <img className="h-[24px] w-[24px]" src={DashIcon} alt="" />
          </div>
          <div>
            <p className="text-[#7b7b7b]">Total Returns</p>
            <h6 className="font-semibold mt-1">$50,000</h6>
          </div>
        </div>
      </div>
      </div>
      <div className="grid grid-cols-12 mt-4">
        <div className="col-span-6 pr-3">
          <div className="bg-[#52bbab] p-5 rounded-[19px]">
            <h1 className="text-[#fff] mb-3 font-semibold text-[20px]">KYC Pending</h1>
            <p className="text-[#fff] font-extralight text-[14px]">
              Your KYC verification is pending. Please complete your KYC to
              unlock full platform access and ensure a secure experience for
              transactions and investments.
            </p>
            <Link to="/">
              <div className="flex items-center gap-3 rounded-[100px] py-3 px-4 mt-4 w-[240px] bg-[#7ef4e1]">
                <p className="text-[#238a7a]">Complete your KYC</p>
                <img className="w-[24px] h-[24px]" src={ButtonIcon} alt="" />
              </div>
            </Link>
          </div>
        </div>
        {wallets.length===0 && <div className="col-span-6 ps-3">
          <div className="bg-[#52bbab] p-5 rounded-[19px]">
            <h1 className="text-[#fff] mb-3 font-semibold text-[20px]">Wallet Pending</h1>
            <p className="text-[#fff] font-extralight text-[14px]">
              Your wallet is not connected. Please connect your wallet to manage
              transactions, track investments, and engage with the platform
              seamlessly.
            </p>
            <Link to="/settings/managewallet">
              <div className="flex items-center gap-3 rounded-[100px] py-3 px-4 mt-4 w-[240px] bg-[#7ef4e1]">
                <p className="text-[#238a7a]">Connect your wallet</p>
                <img className="w-[24px] h-[24px]" src={ButtonIcon} alt="" />
              </div>
            </Link>
          </div>
        </div>}
      </div>
      <div className="graph bg-[#fff] mt-4 rounded-[16px] p-4">
        <h1 id="projectDeliveries" className="font-semibold text-[20px] mb-3">Project Deliveries</h1>
        <LineChart data={graphData} options={chartOptions} />
      </div>
      <div className="p-6 bg-[#fff] mt-4 rounded-[16px]">
        <div className="flex items-center justify-between mb-5">
          <h1 id="investedProjects" className="font-semibold text-[20px]">Invested Projects</h1>
          {/* Search Field */}
          <div className="flex items-center border border-[#56bbac] rounded-full overflow-hidden">
            <input
              type="text"
              placeholder="Search Projects"
              value={searchInput} // Bind local state to input
              onChange={(e) => setSearchInput(e.target.value)}
              className="px-4 py-2 text-[14px] text-[#4a4a4a] outline-none flex-grow"
            />
            <button 
              onClick={()=>setSearch(searchInput)}
              className="px-4 py-2 bg-[#56bbac] text-white text-[14px] font-semibold hover:bg-[#479d94]">
              Search
            </button>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">

          {allProjects && allProjects.length > 0 ? allProjects.map((project, index) => (
            <div className="col-span-3 " key={index}>
              <div className="card shadow-4 rounded-[12px] h-[25rem] overflow-hidden flex flex-col justify-between">
                <div>
                  <img
                    src={project.logo}
                    alt={project.name}
                    className="w-full h-[200px] object-cover"
                  />
                  <p className="text-[10px] bg-[#56bbac] text-[#fff] w-full text-center py-[6px]">
                    {project.category.name}
                  </p>
                </div>
                <div className="p-3 w-full flex flex-col justify-start ">
                  <div className="w-full ">
                    <h1 className="text-[16px] font-semibold">{project.name}</h1>
                    <p className="text-[12px] text-[#9e9b9b]">Total Investment</p>
                    <h1 className="text-[16px] font-semibold my-1">
                      ${project.requiredInvestment.toLocaleString()}
                    </h1>
                    <p className="text-[12px] text-[#9e9b9b] text-justify">
                      {project.description.length > 100
                        ? project.description.slice(0, 100) + "..."
                        : project.description}
                    </p>
                  </div>
                  <div className="flex w-full mt-3 items-end justify-between ">
                    <p className="w-[60px] h-[24px] text-[10px] border border-[#ff6600] bg-[#fff0e5] text-[#ff8f45] text-center flex items-center justify-center rounded-[3px]">
                      Trending
                    </p>
                    <Link
                      to={`/exploredetails/${project._id}`}
                      className="bg-[#52bbab] text-[#fff] rounded-[4px] px-3 py-[2px]}"
                    >
                      View
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )): (
            // Display this div when allProjects is empty
            <div className="col-span-12 flex items-center justify-center h-[200px]">
              <p className="text-center text-[18px] text-gray-500 font-semibold">
                {search ? "No Projects Found For The Given Search Parameter ! " :"You haven't Invested in any project yet , Invest in a project to get started" }
              </p>
            </div>
          )}
        </div>
        <div className="w-full flex text-center items-center justify-center mt-10 mx-auto">
            <div className="flex gap-2 mb-7 justify-center">
              {/* Previous Button */}
              <button
                disabled={page===1}
                onClick={() => page > 1 && setPage(page - 1)}
                className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
              >
                <img className={"h-[10px] w-[7px]"+
                  (page===1? " opacity-40 ":"")} src={prevButton} alt="Previous" />
              </button>

              {/* Page Buttons */}
              {(() => {
                // Calculate start and end of page numbers to display
                const maxVisibleButtons = 3;
                let startPage = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
                let endPage = Math.min(Number(totalPages), startPage + maxVisibleButtons - 1);

                // Adjust startPage if there are fewer pages at the end
                if (endPage - startPage + 1 < maxVisibleButtons) {
                  startPage = Math.max(1, endPage - maxVisibleButtons + 1);
                }

                return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(
                  (pageNum) => (
                    <button
                      key={pageNum}
                      onClick={() => setPage(pageNum)}
                      className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${
                        page === pageNum ? "bg-[#56BBAC] text-white" : ""
                      }`}
                    >
                      {pageNum}
                    </button>
                  )
                );
              })()}

              {/* Next Button */}
              <button
                onClick={() => page < Number(totalPages) && setPage(page + 1)}
                className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
              >
                <img className={"h-[10px] w-[7px]"+
                  (page===Number(totalPages)? " opacity-40 ":"")} src={nextButton} alt="Next" />
              </button>
            </div>
          </div>


      </div>
    </>
  );
};

export default Dashboard;
