import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, setUser } from "./redux/userSlice";
import { RootState } from "./redux/appStore";
import "./css/global.css";
import Cookies from "js-cookie";
import ApiService from "./service/ApiService";
import { apiPaths } from "./service/apiPaths";
import { MetaMaskProvider } from "@metamask/sdk-react"

// Authentication Pages
import Login from "./components/Authentication/Login/Login";
import Signup from "./components/Authentication/Signup/Signup";
import ForgotPassword from "./components/Authentication/ForgotPassword/ForgotPassword";
import VerifyCode from "./components/Authentication/VerifyOtp/VerifyCode";
import NewPassword from "./components/Authentication/NewPassword";

// Layout
import DefaultLayout from "./layout/DefaultLayout";

// Pages
import ExploreIndex from "./page/Explore";
import ExploreDetails from "./page/Explore/Components/ExploreDetails";
import DashboardIndex from "./page/Dashboard";
import MarketOverviewIndex from "./page/MarketOverview";
import TransactionIndex from "./page/Transaction";
import SettingsIndex from "./page/Settings";
import Kyc from "./page/Settings/Components/Kyc";
import Notification from "./page/Settings/Components/Notification";
import ManageWallet from "./page/Settings/Components/ManageWallet";
import Preference from "./page/Settings/Components/Preference";
import HelpIndex from "./page/Help";
import ProfileIndex from "./page/Profile";
import ProjectOverviewIndex from "./page/ProjectOverview";
import OverviewDetails from "./page/ProjectOverview/Components/OverviewDetails";
import ListProjectIndex from "./page/ListProject";
import ActiveInvestorIndex from "./page/ActiveInvestor";
import ProjectManager from "./page/ProjectManager/ProjectManager";
import toast from "react-hot-toast";
import Reown from "./page/ReownWallet/Reown";
import { setIsLoading } from "./redux/loaderSlice";
import StripePayment from "./components/Stripe/StripePayment";

function App() {
  // const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((store: RootState) => store?.user?.isLoggedIn);
  const isProjectManager = useSelector((store:RootState)=>store.user.user?.isProjectManager)
  const isLoading = useSelector((store:RootState)=>store.loader.isLoading)

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.checkTokens,
      });
      if (response?.response) {
        console.log("user : ",response.response.user)
        console.log("TOKENS ARE VERIFIED")
        dispatch(setIsLoggedIn(true));
        dispatch(setUser(response.response.user))
      } else {
        console.log("TOKENS ARE NOT VERIFIED")
        dispatch(setIsLoggedIn(false));
        dispatch(setUser(null));
        // toast.error("Session Expired Please Login Again !")
      }
    } catch (error) {
      console.log("TOKENS ARE NOT VERIFIED")
      dispatch(setIsLoggedIn(false));
      dispatch(setUser(null));
    }finally {
      dispatch(setIsLoading(false)); // Set loading to false after verification
    }
  };

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        {!isLoggedIn && (<>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/verify/signup" element={<VerifyCode />} />
        <Route path="/verify/newpassword" element={<VerifyCode />} />
        <Route path="/newpassword/forgot/:forgotToken" element={<NewPassword />} />
        <Route path="/newpassword/create" element={<NewPassword />} />
       
        </>)}

        {/* Private Routes */}
        <Route
          path="/"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <ExploreIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/exploredetails/:id"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <ExploreDetails />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/projectdetails/:id"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <ExploreDetails investor={true} />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <DashboardIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/marketoverview"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <MarketOverviewIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/transaction"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <TransactionIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/settings"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <SettingsIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/settings/kyc"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <Kyc />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/settings/notification"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <Notification />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/settings/managewallet"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <ManageWallet />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/settings/preference"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <Preference />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/settings/project-manager"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <ProjectManager />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/help"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <HelpIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/profile"
          element={
            isLoggedIn ? (
              <DefaultLayout>
                <ProfileIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/projectoverview"
          element={
            isLoggedIn && isProjectManager ? (
              <DefaultLayout>
                <ProjectOverviewIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        {/* <Route
          path="/overviewdetails"
          element={
            isLoggedIn && isProjectManager  ? (
              <DefaultLayout>
                <OverviewDetails />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        /> */}
        <Route
          path="/listproject"
          element={
            isLoggedIn && isProjectManager  ? (
              <DefaultLayout>
                <ListProjectIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/activeinvestor"
          element={
            isLoggedIn && isProjectManager ? (
              <DefaultLayout>
                <ActiveInvestorIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/investortransaction"
          element={
            isLoggedIn && isProjectManager  ? (
              <DefaultLayout>
                <TransactionIndex />
              </DefaultLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route
          path="/stripe-payment"
          element={
            isLoggedIn  ? (
              <StripePayment></StripePayment>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        {/* <Route
          path="/reown"
          element={
              <DefaultLayout>
                <Reown />
              </DefaultLayout>
          }
        /> */}
        {/* Redirect unknown routes to login */}
        <Route path="*" element={<Navigate to={isLoggedIn ? "/" : "/login"} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
