import React from "react";
import SuccessfullImg from "../../../src/images/icons/successfull.jpg";

const Successfully = ({ closeModal }:{closeModal:any}) => {
  return (
    <div className="successfull-overlayer h-screen w-screen flex items-center justify-center fixed top-0 left-0 right-0 bottom-0">
      <div className="successfull-box flex flex-col items-center justify-center bg-[#fff] p-10 rounded-[30px] max-w-[600px]">
        <img className="h-[100px] w-[100px] mb-4" src={SuccessfullImg} alt="" />
        <h1 className="mb-4 text-[20px]">Successfully Project Listed</h1>
        <p className="text-center text-[14px] w-100 text-[#a4a4a4]">Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum dolor Ut enim ad minim veniam, </p>
        <button onClick={closeModal} className="bg-[#52bbab] mt-5 px-5 py-2 rounded-[12px] text-[#fff]">Done</button>
      </div>
    </div>
  );
};

export default Successfully;