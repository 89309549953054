import React from 'react';
import SettingIcon1 from '../../../images/Settings/setting1.png';
import SettingIcon2 from '../../../images/Settings/setting2.png';
import SettingIcon3 from '../../../images/Settings/setting3.png';
import SettingIcon4 from '../../../images/Settings/setting4.png';
import SettingIcon5 from '../../../images/Settings/setting5.png';
import { Link } from 'react-router-dom';

const Investorsettings = () => {
  return (
    <>
      <div className="bg-[#fff] p-6 rounded-[16px]">
        <h1 className="font-semibold text-[22px]">Settings</h1>
        <p className="text-[#9e9b9b] text-[14px]">
          Manage your account, preferences, and security settings. Customize your experience, update personal details, and control how you interact with the platform for a seamless and secure journey.
        </p>
        <div className="grid grid-cols-12 mt-12 gap-6">
          {/* KYC Card */}
          <div className="col-span-6">
            <Link to="/settings/kyc">
              <div className="card bg-[#fafafa] p-4 flex items-center border  rounded-[16px] hover:shadow-lg transition-shadow duration-300">
                <div className="flex-shrink-0 h-[65px] w-[65px] bg-[#52bbab] rounded-full flex items-center justify-center">
                  <img className="h-[30px] w-[30px]" src={SettingIcon1} alt="KYC Icon" />
                </div>
                <div className="ml-4">
                  <h2 className="text-[20px] text-[#000] font-semibold">KYC</h2>
                  <p className="mt-1 text-[14px] text-[#9e9b9b]">
                    Complete your KYC verification to ensure a secure and compliant experience. Upload documents, track your application status, and maintain trust on the platform.
                  </p>
                </div>
              </div>
            </Link>
          </div>

          {/* Notification Card */}
          <div className="col-span-6">
            <Link to="/settings/notification">
              <div className="card bg-[#fafafa] p-4 flex items-center border  rounded-[16px] hover:shadow-lg transition-shadow duration-300">
                <div className="flex-shrink-0 h-[65px] w-[65px] bg-[#52bbab] rounded-full flex items-center justify-center">
                  <img className="h-[24px] w-[24px]" src={SettingIcon2} alt="Notification Icon" />
                </div>
                <div className="ml-4">
                  <h2 className="text-[20px] text-[#000] font-semibold">Notification</h2>
                  <p className="mt-1 text-[14px] text-[#9e9b9b]">
                    Stay updated with real-time notifications. Get alerts on investments, project updates, and important platform activity to ensure you never miss a key moment.
                  </p>
                </div>
              </div>
            </Link>
          </div>

          {/* Manage Wallet Card */}
          <div className="col-span-6">
            <Link to="/settings/managewallet">
              <div className="card bg-[#fafafa] p-4 flex items-center border  rounded-[16px] hover:shadow-lg transition-shadow duration-300">
                <div className="flex-shrink-0 h-[65px] w-[65px] bg-[#52bbab] rounded-full flex items-center justify-center">
                  <img className="h-[30px] w-[30px]" src={SettingIcon3} alt="Manage Wallet Icon" />
                </div>
                <div className="ml-4">
                  <h2 className="text-[20px] text-[#000] font-semibold">Manage Wallet</h2>
                  <p className="mt-1 text-[14px] text-[#9e9b9b]">
                    Manage your wallets with ease. Add, remove, and monitor your wallets to track balances, transaction history, and ensure secure handling of your funds.
                  </p>
                </div>
              </div>
            </Link>
          </div>

          {/* Preference Card */}
          <div className="col-span-6">
            <Link to="/settings/project-manager">
              <div className="card bg-[#fafafa] p-4 flex items-center border  rounded-[16px] hover:shadow-lg transition-shadow duration-300">
                <div className="flex-shrink-0 h-[65px] w-[65px] bg-[#52bbab] rounded-full flex items-center justify-center">
                  <img className="h-[24px] w-[24px]" src={SettingIcon4} alt="Preference Icon" />
                </div>
                <div className="ml-4">
                  <h2 className="text-[20px] text-[#000] font-semibold">Become Project Manager</h2>
                  <p className="mt-1 text-[14px] text-[#9e9b9b]">
                    Customize your platform experience. Adjust notification settings, privacy options, and display preferences to suit your personal needs and enhance your journey.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          {/* Manager Card */}
          <div className="col-span-6">
            <Link to="/settings/preference">
              <div className="card bg-[#fafafa] p-4 flex items-center border  rounded-[16px] hover:shadow-lg transition-shadow duration-300">
                <div className="flex-shrink-0 h-[65px] w-[65px] bg-[#52bbab] rounded-full flex items-center justify-center">
                  <img className="h-[28px] w-[28px]" src={SettingIcon5} alt="Preference Icon" />
                </div>
                <div className="ml-4">
                  <h2 className="text-[20px] text-[#000] font-semibold">Preference</h2>
                  <p className="mt-1 text-[14px] text-[#9e9b9b]">
                    Customize your platform experience. Adjust notification settings, privacy options, and display preferences to suit your personal needs and enhance your journey.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investorsettings;
