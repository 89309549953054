import React, { useEffect, useState } from "react";
import RightIcon from "../../../images/Settings/right.png";
import ManageImage from "../../../images/Settings/manage.png";
import AddButton from "../../../images/Settings/delete.png";
import { Link } from "react-router-dom";
import { useManageWallet } from "./useManageWallet";
import { Wallet } from "./Wallet.prop";
import formatDate from "../DateFormat";
import toast from "react-hot-toast";
import { useSDK } from "@metamask/sdk-react";

const ManageWallet = () => {
  const { getWallets, addWallet, loading, connectWallet } = useManageWallet();
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [addLoader, setAddLoader] = useState<boolean>(false);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [confirmConnection, setConfirmConnection] = useState<boolean>(false); // New state for confirmation modal
  const [selectedWallet, setSelectedWallet] = useState<string>(""); // Track selected wallet

  const [account, setAccount] = useState<string>();
  const { sdk, connected, connecting, provider, chainId } = useSDK();

  const connect = async () => {
    try {
      const accounts = await sdk?.connect();
      if(accounts && accounts.length>0){
       const data = await  addWalletHandler(accounts?.[0])
      }
      console.log(accounts)
    } catch (err) {
      console.warn("failed to connect..", err);
    }
  };

  useEffect(() => {
    const fetchWallets = async () => {
      const walletsData: Wallet[] | null = await getWallets();
      if (walletsData) {
        setWallets(walletsData);
      }
    };
    fetchWallets();
  }, []);

  async function addWalletHandler(newWalletAddress:string) {
    setAddLoader(true); // Show the add wallet loader
    const response = await addWallet(newWalletAddress);
    if (response) {
      setWallets([...wallets, response]);
      toast.success("Wallet Added Successfully")
    }
    setAddLoader(false); // Hide the loader when done
  }

  async function connectWalletHandler(wallet: string) {
    setIsConnecting(true); // Show connecting modal
    const response = await connectWallet(wallet);
    if (response) {
      setWallets((prevWallets) =>
        prevWallets.map((w) =>
          w.walletAddress === wallet
            ? { ...w, isConnected: true }
            : { ...w, isConnected: false }
        )
      );
      toast.success("Wallet Connected Successfully")
    }else{
      toast.error("Error Occured")
    }
    setIsConnecting(false); // Hide connecting modal
    setConfirmConnection(false); // Close confirmation modal
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   setNewWalletAddress("");
  // };

  // const handleModalSubmit = () => {
  //   if (newWalletAddress) {
  //     addWalletHandler();
  //     closeModal();
  //   }
  // };

  const handleConnectionRequest = (wallet: string, isConnected: boolean) => {
    if (isConnected) return;
    setSelectedWallet(wallet); // Set selected wallet for confirmation
    setConfirmConnection(true); // Open confirmation modal
  };

  const confirmConnect = () => {
    if (selectedWallet) {
      connectWalletHandler(selectedWallet);
    }
  };

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center">Loading Wallets...</div>
    );
  }

  return (
    <>
      <div className="bg-[#fff] rounded-[16px]">
        <div className="p-6 border-b-2">
          <div className="flex items-center gap-2 font-semibold">
            <Link to="/settings">
              <h1 className="mb-0 text-[20px]">Settings</h1>
            </Link>
            <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />
            <h1 className="mb-0 text-[20px] text-[#52bbab]">Wallets</h1>
          </div>
          <p className="text-[14px] text-[#9e9b9b]">
            Customize your platform experience. Adjust notification settings,
            privacy options, and display preferences to suit your personal
            needs and enhance your journey.
          </p>
        </div>
        <div className="p-6">
          <div className="flex items-center justify-between">
            <p className="font-semibold text-[20px]">
              Wallet Connect Integration
            </p>
            <button
              className="bg-[#52bbab] rounded-[8px] px-6 py-2 text-[#fff]"
              onClick={connect}
            >
              +Add a New Wallet
            </button>
          </div>
          <div className="flex items-center mt-4 bg-[#f6f6f6] p-6 rounded-[16px] gap-4">
            <img className="w-[65px] h-[72px]" src={ManageImage} alt="" />
            <div>
              <h1 className="font-medium text-[20px]">
                WalletConnect acts as a bridge, facilitating a secure and
                seamless connection between decentralized applications and
                various Web3 wallets.
              </h1>
              <p className="text-[#999696]">
                Note: Primary wallet is the default wallet that will be added to
                the multisig contracts.
              </p>
            </div>
          </div>
          <div className="w-full h-full grid grid-cols-2 gap-4">
            {wallets.map((wallet: Wallet) => (
              <div
                key={wallet._id}
                className="wallet-card rounded-[16px] p-6 bg-[#dff5f1] mt-4 w-[390px]"
              >
                <div className="flex items-center justify-between">
                  <p className="text-[12px] text-[#9da8a5]">{wallet._id}</p>
                  <p className="text-[14px] text-[#9da8a5]">
                    {formatDate(wallet.createdAt)}
                  </p>
                </div>
                <p className="font-medium">
                  {wallet.walletAddress.substring(0, 5) +
                    "....." +
                    wallet.walletAddress.substring(
                      wallet.walletAddress.length - 5,
                      wallet.walletAddress.length
                    )}
                </p>
                <div className="flex mt-3 gap-4">
                  <button className="bg-[#52bbab] rounded-[12px] text-[#fff] px-5 py-3">
                    Primary Wallet
                  </button>
                  <button
                    className="bg-[#52bbab] rounded-[12px] text-[#fff] px-5 py-3"
                    onClick={() =>
                      handleConnectionRequest(
                        wallet.walletAddress,
                        wallet.isConnected
                      )
                    }
                  >
                    {wallet.isConnected ? "Connected" : "Connect"}
                  </button>
                </div>
                <div className="flex mt-8 items-center justify-between">
                  <p className="font-medium">Balance: 0.12 AVAX</p>
                  <img className="h-[20px] w-[20px]" src={AddButton} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Add Wallet Modal */}
      {/* {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[400px]">
            <h2 className="text-[20px] font-semibold mb-4">
              Enter Wallet Address
            </h2>
            <input
              type="text"
              value={newWalletAddress}
              onChange={(e) => setNewWalletAddress(e.target.value)}
              className="w-full p-2 mb-4 border rounded-md"
              placeholder="Enter Wallet Address"
            />
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-300 text-white px-4 py-2 rounded-md"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className="bg-[#52bbab] text-white px-4 py-2 rounded-md"
                onClick={handleModalSubmit}
              >
                {addLoader ? "Submitting.." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )} */}

      {/* Connecting Confirmation Modal */}
      {confirmConnection && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[400px]">
            <h2 className="text-[20px] font-semibold mb-4">
              Do you wish to connect this wallet?
            </h2>
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-300 text-white px-4 py-2 rounded-md"
                onClick={() => setConfirmConnection(false)}
              >
                No
              </button>
              <button
                className="bg-[#52bbab] text-white px-4 py-2 rounded-md"
                onClick={confirmConnect}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Connecting Modal */}
      {isConnecting && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[300px] text-center">
            <h2 className="text-[20px] font-semibold mb-4">Connecting...</h2>
            <p>Please wait while we connect your wallet.</p>
          </div>
        </div>
      )}

      {addLoader && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[300px] text-center">
            <h2 className="text-[20px] font-semibold mb-4">Adding Wallet...</h2>
            <p>Please wait while we add your wallet.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageWallet;
