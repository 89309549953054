import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import appStore from "./redux/appStore";
import { Toaster } from "react-hot-toast";
import { MetaMaskProvider } from "@metamask/sdk-react";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={appStore}>
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <MetaMaskProvider
      sdkOptions={{
        dappMetadata: {
          name: "Example React Dapp",
          url: window.location.href,
        },
        infuraAPIKey: "4c7068ebb8944d6ea25bbe6b0864837a",
        // Other options.
      }}
    >
      <App />
      </MetaMaskProvider>
    </>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();