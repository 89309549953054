import React, { useState, useEffect } from "react";
import { GraphData } from "../MarketOverview.props";
import { Bar } from "react-chartjs-2";
import LineChart from "../../../components/Graph/Line";
import BarChart from "../../../components/Graph/Bar";
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import { ProjectResponse } from "../../Dashboard/Dashboard.props";
import { useExplore } from "../../Explore/useExplore";
import { Link } from "react-router-dom";

// Define the structure of the project data (replace this with actual type from your backend or API)
interface ProjectData {
  id: number;
  name: string;
  amount: string;
  volume: number;
  marketCap: number;
}

const MarketOverview = () => {
  const { getProjects } = useExplore();
  const [allProjects, setAllProjects] = useState<ProjectResponse[]>([]);

  const [graphData, setGraphData] = useState<GraphData>({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Monthly Patient Count",
        data: [10, 9, 11, 12, 11, 8, 10, 11, 10, 12, 10],
        backgroundColor: "rgba(75, 192, 192,1)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });

  // Data for the market overview table
  const [projects, setProjects] = useState<ProjectData[]>([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Number of items to display per page

  useEffect(() => {
    // Function to fetch data (you would replace this with your API call)
    const fetchData = async () => {
      // Simulate fetching data for a specific page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      // Assuming you have a list of all projects; paginate here (replace with your real API call)
      const allProjects: ProjectData[] = [
        { id: 1, name: "Palameku ..", amount: "150000 €", volume: 1200000, marketCap: 120000000 },
        { id: 2, name: "Palameku ..", amount: "150000 €", volume: 1200000, marketCap: 120000000 },
        { id: 3, name: "Palameku ..", amount: "150000 €", volume: 1200000, marketCap: 120000000 },
        { id: 4, name: "Palameku ..", amount: "150000 EURO", volume: 1200000, marketCap: 120000000 },
        { id: 5, name: "Palameku ..", amount: "150000 EURO", volume: 1200000, marketCap: 120000000 },
        { id: 6, name: "Palameku ..", amount: "150000 €", volume: 1200000, marketCap: 120000000 },
        { id: 7, name: "Palameku ..", amount: "150000 €", volume: 1200000, marketCap: 120000000 },
        { id: 8, name: "Palameku ..", amount: "150000 €", volume: 1200000, marketCap: 120000000 },
        { id: 9, name: "Palameku ..", amount: "150000 €", volume: 1200000, marketCap: 120000000 },
        { id: 10, name: "Palameku ..", amount: "150000 €", volume: 1200000, marketCap: 120000000 },
        { id: 11, name: "Palameku ..", amount: "150000 €", volume: 1200000, marketCap: 120000000 },
      ]; // Sample data for pagination (replace with real API response)

      const paginatedProjects = allProjects.slice(startIndex, endIndex);
      setProjects(paginatedProjects);
      setTotalPages(Math.ceil(allProjects.length / itemsPerPage)); // Calculate total pages
    };

    fetchData();
  }, [currentPage, itemsPerPage]);




  useEffect(()=>{
    async function fetchProjects() {
      const response = await getProjects("" , currentPage  , 5);
      if (response && Array.isArray(response.docs)) {
        setAllProjects(response.docs);
        setTotalPages(response.totalPages)
        console.log(response)
      } else {
        console.error('Error fetching details, using dummy data');
        // setAllProjects(dummyData);
      }
    }
    console.log("hiiii")
    fetchProjects();
  },[currentPage])

  return (
    <div className="p-5 rounded-[16px] bg-[#fff]">
      <h1 className="font-semibold text-[22px]">Market Overview</h1>
      <p className="text-[14px] mt-2 text-[#9e9b9b]">
        Get a snapshot of the green project market, with insights into active
        projects, total investments, and token trends. Stay informed and make
        impact-driven investment decisions, helping shape a sustainable
        future.
      </p>
      <div className="grid grid-cols-12 mt-4 gap-3">
        <div className="col-span-6 h-full">
          <div className="card border h-full rounded-[12px] p-5">
            <div className="border-b-2 pb-3 mb-4">
              <div className="flex items-center gap-4">
                <h1 className="font-semibold text-[18px]">
                  Trade Volume on platform
                </h1>
                <p className="flex items-center justify-center rounded-[25px] px-2 bg-[#ecf7ef] text-[#2ab150]">
                  Label
                </p>
              </div>
              <p className="text-[#475f6b] text-[14px]">
                Track the total trade volume across the platform
              </p>
            </div>
            <div className="graph">
              <LineChart data={graphData} />
            </div>
          </div>
        </div>
        <div className="col-span-6 h-full">
          <div className="card border h-full rounded-[12px] p-5">
            <div className="border-b-2 pb-3 mb-4">
              <div className="flex items-center gap-4">
                <h1 className="font-semibold text-[18px]">
                  #1 Trending Project
                </h1>
                <p className="flex items-center justify-center rounded-[25px] px-2 bg-[#ecf7ef] text-[#2ab150]">
                  Label
                </p>
              </div>
              <p className="text-[#475f6b] text-[14px]">
                Discover the #1 trending green project on the platform
              </p>
            </div>
            <div className="graph">
              <BarChart data={graphData} />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4  border border-[#dcdcdc] rounded-[14px] overflow-hidden">
       { allProjects && allProjects.length>0 ? (<div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-sm">
            <thead>
              <tr className="bg-gray-100 text-gray-600 text-sm uppercase">
                <th className="py-3 px-6 text-left">Sr. No.</th>
                <th className="py-3 px-6 text-left">Name</th>
                <th className="py-3 px-6 text-left">Amount</th>
                <th className="py-3 px-6 text-left">Volume</th>
                <th className="py-3 px-6 text-left">Market cap</th>
                <th className="py-3 px-6 text-left">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {allProjects.map((project, index) => (
                <tr
                  key={project._id}
                  className="border-b border-gray-200 hover:bg-gray-50"
                >
                  <td className="py-3 px-6">{index + 1}</td>
                  <td className="py-3 px-6">{project.name}</td>
                  <td className="py-3 px-6">{project.requiredInvestment}</td>
                  <td className="py-3 px-6">{project.minInvestment}</td>
                  <td className="py-3 px-6">{project.lockInPeriod}</td>
                 
                  <Link to=  {`/projectdetails/${project._id}`}>
                  <td className="py-3 px-6 text-blue-500 cursor-pointer">
                    View
                  </td>
                  </Link>
                </tr>
              ))}
            </tbody>
          </table>
        </div>):(<p>No Trending Projects  Founnd</p>)
}
      </div>

      {/* Pagination Controls */}
      <div className="flex w-full justify-center mx-auto gap-2 mt-6 mb-1">
        <button
          onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
          className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
          disabled={currentPage === 1}
        >
          <img className="h-[10px] w-[7px]" src={prevButton} alt="Previous" />
        </button>

        {/* Page Numbers */}
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => setCurrentPage(i + 1)}
            className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${
              currentPage === i + 1 ? "bg-[#56BBAC] text-white" : ""
            }`}
          >
            {i + 1}
          </button>
        ))}

        <button
          onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages))}
          className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
          disabled={currentPage === totalPages}
        >
          <img className="h-[10px] w-[7px]" src={nextButton} alt="Next" />
        </button>
      </div>
    </div>
  );
};

export default MarketOverview;
