import React, { useState } from "react";
import UploadIcon from "../../../images/icons/upload.png";
import BackIcon from "../../../images/icons/back.png";
import { useListProject } from "../useListProject";
import { ChangeEvent } from "react";
import axios from "axios";
import { apiPaths } from "../../../service/apiPaths";
import { ListProjectResponse } from "../listProject.props";
import { FormDataInterface } from "../interface";
import toast from "react-hot-toast";
import Successfully from "../../../components/Successfully";
import { useNavigate } from "react-router-dom";
import { useRef , useEffect } from "react";

const MultiStepForm = () => {
  const { listProject,  error } = useListProject();

  const sessionStep = sessionStorage.getItem('listProjectStep')
  const [step, setStep] = useState(Number(sessionStep) || 1);
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate()

  interface FileUploadResponse {
    message: string;
    files: string[];
  }

  
  const projectTypes = [
    {
      _id: "670fbc729aedcfcb302862ce",
      name: "Renewable Energy Projects",
      createdAt: "2024-10-16T00:00:00.000+00:00",
      updatedAt: "2024-10-16T00:00:00.000+00:00",
    },
    {
      _id: "670fbc799aedcfcb302862cf",
      name: "Energy Efficiency Projects",
      createdAt: "2024-10-16T00:00:00.000+00:00",
      updatedAt: "2024-10-16T00:00:00.000+00:00",
    },
    {
      _id: "670fbc809aedcfcb302862d0",
      name: "Sustainable Agriculture Projects",
      createdAt: "2024-10-16T00:00:00.000+00:00",
      updatedAt: "2024-10-16T00:00:00.000+00:00",
    },
    {
      _id: "670fbc869aedcfcb302862d1",
      name: "Waste Management and Recycling Projects",
      createdAt: "2024-10-16T00:00:00.000+00:00",
      updatedAt: "2024-10-16T00:00:00.000+00:00",
    },
    {
      _id: "670fbc8c9aedcfcb302862d2",
      name: "Green Transportation Projects",
      createdAt: "2024-10-16T00:00:00.000+00:00",
      updatedAt: "2024-10-16T00:00:00.000+00:00",
    },
  ];
  const [formData, setFormData] = useState<FormDataInterface>({
    phoneNumber: "987654321",
    name: "",
    geographicMarket: "",
    postalCode: "",
    symbol: "",
    email: "",
    ticker: "test",
    category: "670fbc729aedcfcb302862ce",
    otherDocument: [],
    description: "",
    images: [],
    requiredInvestment: 0,
    currenciesAccepted: [],
    projectPhases: [{ phaseName: "test", phaseDescription: "test" }],
    auditReports: "test",
    compliance: ["test"],
    lockInPeriod: 0,
    maxSupply: 0,
    website: "",
    owner: "0x001",
    burn: 0,
    minInvestment: 0,
    projectType: "",
    projectStage: "running",
    balanceSheet: "",
    logo: "",
    
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const currencies = [
    {
      id: "670fbc969aedcfcb302862d3",
      name: "US Dollar",
      symbol: "$",
      code: "usd",
    },
    {
      id: "670fbc9b9aedcfcb302862d4",
      name: "Euro",
      symbol: "€",
      code: "eur",
    },
    {
      id: "670fbca09aedcfcb302862d5",
      name: "British Pound Sterling",
      symbol: "£",
      code: "gbp",
    },
    {
      id: "670fbca59aedcfcb302862d6",
      name: "Japanese Yen",
      symbol: "¥",
      code: "jyp",
    },
  ];
  const selectedCurrencies = currencies.filter(currency =>
    formData.currenciesAccepted.includes(currency.id)
  );
  
  interface FilesState {
    projectLogo: File | null;
    balanceSheet: File | null;
    otherDocument: File[];
    additionalImages: File[];
  }

  const [files, setFiles] = useState<FilesState>({
    projectLogo: null,
    balanceSheet: null,
    otherDocument: [],
    additionalImages: [],
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleNext = () => {
    if (step < 4) {
      setStep(step + 1);
    } else {
      // setIsModalOpen(true);
      uploadFiles() 
    }
  };
  const closeModal = () => {
    navigate("/projectoverview")
    setIsModalOpen(false); // Close modal
  };

  // const handleNext = () => {
  //   if (step < 4) {
  //     setStep(step + 1);
  //   }
  // };

  const getProjectType = (projectId:string)=>{
    const data =  projectTypes.filter((project)=>project._id===projectId)
    console.log(data)
    return data[0]

  }

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleStepClick = (selectedStep: number) => {
    setStep(selectedStep);
  };
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  //USE EFFECT TO FETCH DATA FROM SESSION STORAGE 
  useEffect(()=>{
    const savedData = sessionStorage.getItem('formData');
    // const sessionSavedStep = sessionStorage.get
    if(savedData){
      setFormData(JSON.parse(savedData))
    }
  },[])

  // USE EFFECT HOOK TO STORE DATA IN SESSION STORAGE
  useEffect(()=>{
    sessionStorage.setItem("formData",JSON.stringify(formData))
    sessionStorage.setItem('listProjectStep',String(step))
  },[formData,step])

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof FilesState
  ) => {
    const filesArray = e.target.files ? Array.from(e.target.files) : [];
  
    if (field === "additionalImages" || field === "otherDocument") {
      if (field === "additionalImages" && files.additionalImages.length >= 5) {
        setFiles((prevFiles) => ({
          ...prevFiles,
          [field]: [...prevFiles[field].slice(0, 4), ...filesArray], // Replace last file
        }));
        return;
      }
  
      // Check if the field is for other documents
      if (field === "otherDocument" && files.otherDocument.length >= 5) {
        // If 5 files, remove the last one and add the new file(s)
        setFiles((prevFiles) => ({
          ...prevFiles,
          [field]: [...prevFiles[field].slice(0, 4), ...filesArray], // Replace last file
        }));
        return;
      }
  
      // If less than 5 files, simply append new ones
      setFiles((prevFiles) => ({
        ...prevFiles,
        [field]: [...prevFiles[field], ...filesArray],
      }));
    } else {
      // Handle other fields (single file)
      setFiles((prevFiles) => ({
        ...prevFiles,
        [field]: filesArray[0] || null,
      }));
    }
  };
  

  const handleCurrenciesAcceptedChange = (currencyId: string) => {
    setFormData((prevFormData) => {
      const isSelected = prevFormData.currenciesAccepted.includes(currencyId);
      const updatedSelection = isSelected
        ? prevFormData.currenciesAccepted.filter((id) => id !== currencyId)
        : [...prevFormData.currenciesAccepted, currencyId];
      return { ...prevFormData, currenciesAccepted: updatedSelection };
    });
  };

  const handleSubmit = async (updatedformdata: FormDataInterface) => {
    console.log(formData);
    try {
      const response = await listProject(updatedformdata);

      console.log(response);

      if (response && response.success) {
        setIsModalOpen(true)
        setStep(1);

        setFormData({
          phoneNumber: "",
          name: "",
          geographicMarket: "",
          postalCode: "",
          symbol: "",
          email: "",
          ticker: "111",
          category: "",
          description: "",
          otherDocument: [],
          images: [],
          requiredInvestment: 0,
          currenciesAccepted: [],
          projectPhases: [],
          auditReports: "",
          compliance: [],
          lockInPeriod: 0,
          maxSupply: 0,
          website: "",
          owner: "",
          burn: 0, 
          minInvestment: 0,
          projectType: "",
          projectStage: "",
          balanceSheet: "",
          logo: "",
        });

        setFiles({
          projectLogo: null,
          balanceSheet: null,
          otherDocument: [],
          additionalImages: [],
        });
      } else {
        toast.error("Error Occured");
        console.error("Response failed:", response);
      }
    } catch (error) {
      toast.error("Error occurred");
      console.error("Error submitting form:", error);
    }finally{
      setLoader(false)
    }
  };

  const uploadFiles = async () => {

    if(!validateFormData()){
      return 
    }
    try {
      setLoader(true)
      let updatedformdata = { ...formData };

      
      if (files.balanceSheet && formData.balanceSheet === "") {
        let formDataInstance = new FormData();
        formDataInstance.append("files", files.balanceSheet);

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          updatedformdata.balanceSheet = response.data.files[0];
        } catch (error) {
          console.error("Error uploading balance sheet:", error);
          setLoader(false)
          toast.error("Error Occured")

          throw error;
           
        }
      }

      // Upload project logo
      if (files.projectLogo && formData.logo === "") {
        let formDataInstance = new FormData();
        formDataInstance.append("files", files.projectLogo);

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          updatedformdata.logo = response.data.files[0];
        } catch (error) {
          console.error("Error uploading project logo:", error);
          setLoader(false)
          toast.error("Error Occured")

          throw error;
        }
      }

      // Upload additional images
      if (files.additionalImages.length > 0 && formData.images.length === 0) {
        let formDataInstance = new FormData();
        files.additionalImages.forEach((image) => {
          formDataInstance.append("files", image);
        });

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          updatedformdata.images = response.data.files;
        } catch (error) {
          setLoader(false)
          toast.error("Error Occured")
          throw error;
        }
      }

      if (
        files.otherDocument.length > 0 &&
        formData.otherDocument.length === 0
      ) {
        let formDataInstance = new FormData();
        files.otherDocument.forEach((doc) => {
          formDataInstance.append("files", doc);
        });

        try {
          const response = await axios.post<FileUploadResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/` + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          updatedformdata.otherDocument = response.data.files;
        } catch (error) {
          setLoader(false)
          console.error("Error uploading other documents:", error);
          toast.error("Error Occured")

          throw error;
        }
      }
      console.log(updatedformdata);

      return handleSubmit(updatedformdata);
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoader(false)
      throw error;
    } 
  };



  const validateFormData = () => {
    const errors: { [key in keyof FormDataInterface]?: string } = {}; 
  
    const urlPattern =   /^https:\/\/.*$/
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    const phonePattern = /^[0-9]+$/; 
    const numberPattern = /^[0-9]+(\.[0-9]+)?$/; 
  
    for (const field in formData) {
      const key = field as keyof FormDataInterface;
      if(["auditReports" , "balanceSheet" ,"ticker" , "owner" , "compliance" , "auditReports" , "projectPhases" , "images" , "otherDocument" , "logo"].includes(key.toString())){
        continue
      }   
  
      if (formData[key] === "" || formData[key] === null || formData[key] === undefined) {
        errors[key] = `${key} is required`;
        toast.error([errors[key]]+"")
        return false
      }
    }

    if(files.additionalImages.length===0 || !files.balanceSheet || !files.projectLogo || files.otherDocument.length===0){
              toast.error("All Fields Are Required")
              return false
    }
  
    if (formData.phoneNumber && !phonePattern.test(formData.phoneNumber)) {
      toast.error("Invalid Phone Number")
      errors.phoneNumber = "Phone number should contain only numbers";
      return false 
    }

    if(!emailPattern.test(formData.email)){
      toast.error("Enter A Valid Email Address")
      errors.email = "Enter A Valid Email Address"
      return false
    }
  
    if (formData.website && !urlPattern.test(formData.website)) {
      toast.error("Please Enter A Valid Url")
      errors.website = "Website should start with https";

      return false


    }

    if(!numberPattern.test(formData.postalCode) ||  formData.postalCode.toString().length<6){
      toast.error("Enter a Valid Postal Code")
      return false

 
    }
  
    if (formData.burn && !numberPattern.test(formData.burn.toString())) {
      toast.error("Invalid Burn Amount")
      errors.burn = "Burn should be a valid number";
      return false
    }
    if (formData.minInvestment && !numberPattern.test(formData.minInvestment.toString())) {
      toast.error("Invalid Minium Investment Amount")
      errors.minInvestment = "Minimum Investment should be a valid number";
      return false
    }
    if (formData.requiredInvestment && !numberPattern.test(formData.requiredInvestment.toString())) {
      toast.error("Invalid Required Investment amount")
      errors.requiredInvestment = "Required Investment should be a valid number";
      return false
    }

    if(!numberPattern.test(formData.lockInPeriod.toString())){
      toast.error("Enter A Valid Lock In Period")
      errors.lockInPeriod="Enter A Valid Lock In Period"
      return false
    }

    if(!numberPattern.test(formData.maxSupply.toString())){
      toast.error("Enter A Valid Token Supply")
      errors.lockInPeriod="Enter A Valid Token Suplly"
      return false
    }
  
    // If no errors, return true, otherwise return the errors object
    if (Object.keys(errors).length === 0) {
      return true; // No errors
    }
  
    return errors; // Return the errors object if validation fails
  };

// Toggle the dropdown visibility

  
  

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="grid grid-cols-12 gap-x-12 gap-y-4">
              <div className="col-span-6">
                <p className="mb-2">Project Name</p>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter Your Project Name"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
              <div className="col-span-6">
                <p className="mb-2">Project Symbol</p>
                <input
                  type="text"
                  name="symbol"
                  value={formData.symbol}
                  onChange={handleChange}
                  placeholder="Enter Your Project Symbol"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
              <div className="col-span-6">
                <p className="mb-2">Email Address</p>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Your Email Address"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
              <div className="col-span-6">
                <p className="mb-2">Postal Code</p>
                <input
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                  placeholder="Enter Your Postal Code"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
              <div className="col-span-6">
                <p className="mb-2">Website URL</p>
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  placeholder="Enter Your Website URL"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
              <div className="col-span-6">
                <p className="mb-2">Phone Number</p>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter Your Phone Number"
                  className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                />
              </div>
              <div className="col-span-12">
                <p className="mb-2">Project Description</p>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Enter Your Project Description"
                  className="block border p-2 mb-2 w-full bg-[#eeeeee] rounded-[12px] px-4 py-[12px]"
                />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
           <div className="grid grid-cols-12 gap-x-12 gap-y-4">
  <div className="col-span-6">
    <p className="mb-2">Required Investment</p>
    <input
      type="text"
      name="requiredInvestment"
      value={formData.requiredInvestment}
      onChange={handleChange}
      placeholder="Enter Required Investment"
      className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
    />
  </div>

  <div className="col-span-6">
    <p className="mb-2">Percentage to Burn</p>
    <input
      type="text"
      name="burn"
      value={formData.burn}
      onChange={handleChange}
      placeholder="Enter Percentage to Burn"
      className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
    />
  </div>

  <div className="col-span-6">
    <p className="mb-2">Min Investment</p>
    <input
      type="text"
      name="minInvestment"
      value={formData.minInvestment}
      onChange={handleChange}
      placeholder="Enter Minimum Investment"
      className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
    />
  </div>

  <div className="col-span-6">
    <p className="mb-2">Lock In Period</p>
    <input
      type="text"
      name="lockInPeriod"
      value={formData.lockInPeriod}
      onChange={handleChange}
      placeholder="Enter Lock In Period"
      className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
    />
  </div>

  <div className="col-span-6">
    <p className="mb-2">Max Token Supply</p>
    <input
      type="text"
      name="maxSupply"
      value={formData.maxSupply}
      onChange={handleChange}
      placeholder="Enter Max Token Supply"
      className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
    />
  </div>

  {/* Currencies Accepted Dropdown */}
  <div className="col-span-6 relative">
    <p className="mb-2 text-sm text-gray-600">Currencies Accepted</p>

    {/* Selected Currencies Display */}
    <div
      onClick={toggleDropdown}
      className="block w-full text-sm bg-[#eeeeee] rounded-[12px] px-4 py-3 border cursor-pointer focus:outline-none focus:ring-2 focus:ring-[#52bbab] focus:border-[#52bbab] relative"
    >
      <p className="text-[#827f7c] text-sm">
        {formData.currenciesAccepted.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {formData.currenciesAccepted.map((currencyId) => {
              const currency = currencies.find((curr) => curr.id === currencyId);
              return (
                currency && (
                  <span
                    key={currency.id}
                    className="bg-teal-500 text-white text-xs rounded-full px-2 py-1 flex items-center gap-1"
                  >
                    {currency.symbol} {currency.name}
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent dropdown from closing
                        handleCurrenciesAcceptedChange(currency.id);
                      }}
                      className="text-white text-xs bg-transparent border-none cursor-pointer"
                    >
                      &times;
                    </button>
                  </span>
                )
              );
            })}
          </div>
        ) : (
          "Select currencies"
        )}
      </p>
    </div>

    {/* Dropdown List */}
    {isDropdownOpen && (
      <div
        ref={dropdownRef}
        className="absolute left-0 mt-1 max-h-60 overflow-auto bg-white border rounded-[12px] shadow-lg z-10 w-full sm:w-[300px] max-w-full"
      >
        {currencies.map((currency) => (
          <div
            key={currency.id}
            onClick={() => handleCurrenciesAcceptedChange(currency.id)}
            className={`p-2 cursor-pointer hover:bg-[#52bbab]/20 transition-all ${
              formData.currenciesAccepted.includes(currency.id)
                ? "bg-[#52bbab] text-white"
                : "text-[#827f7c]"
            }`}
          >
            {currency.name} ({currency.symbol})
          </div>
        ))}
      </div>
    )}
  </div>
</div>

          </>
        );
      case 3:
        return (
          <>
            <div className="grid grid-cols-12 gap-x-12 gap-y-4">
              <div className="col-span-6">
                <p className="mb-2">Project Type</p>
                <select
                  name="projectType"
                  value={formData.projectType || ""}
                  onChange={handleChange}
                  className="block text-[#827f7c] text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border focus:outline-none focus:ring-2 focus:ring-[#52bbab] focus:border-[#52bbab] w-full cursor-pointer"
                >
                  <option value="" className="text-[#827f7c]">
                    Select your project type
                  </option>
                  {projectTypes.map((project) => (
                    <option
                      key={project._id}
                      value={project._id}
                      className={`text-[#827f7c] text-[14px] ${
                        formData.projectType === project._id
                          ? "bg-[#52bbab] text-white"
                          : "hover:bg-[#52bbab]/20"
                      }`}
                    >
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-6">
                <p className="mb-2">Project Stage</p>
                <select className="block text-[#827f7c] text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full">
                  <option value="" className="text-[#827f7c] text-[14px]">
                    Select your Project Stage
                  </option>
                  <option
                    value="Initial"
                    className="text-[#827f7c] text-[14px]"
                  >
                    Initial
                  </option>
                  <option
                    value="Intermediate"
                    className="text-[#827f7c] text-[14px]"
                  >
                    Intermediate
                  </option>
                  <option value="Ready" className="text-[#827f7c] text-[14px]">
                    Ready
                  </option>
                </select>
              </div>
              <div className="col-span-6">
                <p className="mb-2">Geographic Market</p>
                <input
                  type="text"
                  name="geographicMarket" // Name should match the state property (geographicMarket)
                  placeholder="Enter Your Max Geographic Market"
                  onChange={handleChange}
                  className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border p-2 mb-2 w-full"
                  value={formData.geographicMarket} // This binds the value to the geographicMarket property in the state
                />
              </div>

              <div className="col-span-6">
      <p className="mb-2">Project Logo/Icon</p>

      {/* Label for file input */}
      <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
        <input
          type="file"
          className="hidden"
          onChange={(e) => handleFileChange(e, "projectLogo")}
        />

        <div className="flex justify-between items-center">
          <p className="text-gray-500 text-[14px]">
            {files.projectLogo ? (
              <span>{files.projectLogo.name}</span> // Show selected file name
            ) : (
              "Upload your project logo"
            )}
          </p>

          {/* Upload Icon */}
          <img
            className="w-[23px] h-[15px]"
            src={UploadIcon}
            alt="Upload Icon"
          />
        </div>
      </label>

      {/* If a logo is selected, show the logo image */}
      {files.projectLogo && (
        <div className="mt-4">
          <img
            src={URL.createObjectURL(files.projectLogo)} // Show the image
            alt="Project Logo"
            className="w-24 h-24 object-cover rounded-full" // Adjust size as needed
          />
        </div>
      )}
    </div>
              <div className="col-span-6">
                <p className="mb-2">Balance sheet</p>
                <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => handleFileChange(e, "balanceSheet")}
                  />
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-[14px]">
                      {files.balanceSheet
                        ? files.balanceSheet?.name
                        : "Upload your balance sheet"}
                    </p>
                    <img
                      className="w-[16px] h-[16px]"
                      src={UploadIcon}
                      alt="Upload Icon"
                    />
                  </div>
                </label>
              </div>

              <div className="col-span-6">
                <p className="mb-2">Other Documents</p>
                <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
                  <input
                    type="file"
                    className="hidden"
                    multiple
                    onChange={(e) => handleFileChange(e, "otherDocument")}
                  />
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-[14px]">
                      {files.otherDocument.length > 0
                        ? `${files.otherDocument.length} files selected`
                        : "Maximum 5 documents can be allowed"}
                    </p>
                    <img
                      className="w-[23px] h-[15px]"
                      src={UploadIcon}
                      alt="Upload Icon"
                    />
                  </div>
                </label>
                {/* Display list of uploaded files */}
                <ul className="mt-2 space-y-1">
                  {files.otherDocument.map((file, index) => (
                    <li
                      key={index}
                      className="text-gray-600 text-[12px] truncate"
                    >
                      {file.name}
                    </li>
                  ))}
                </ul>
                {/* {files.otherDocument.length >= 5 && (
          <p className="text-red-500 text-xs mt-2">You can upload up to 5 documents only.</p>
        )} */}
              </div>

              <div className="col-span-12">
                <p className="mb-2">Additional Images</p>
                <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
                  <input
                    type="file"
                    className="hidden"
                    multiple
                    onChange={(e) => handleFileChange(e, "additionalImages")}
                  />
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-[14px]">
                      {files.additionalImages.length > 0
                        ? `${files.additionalImages.length} files selected`
                        : "Maximum 5 images can be selected"}
                    </p>
                    <img
                      className="w-[23px] h-[15px]"
                      src={UploadIcon}
                      alt="Upload Icon"
                    />
                  </div>
                </label>
                <ul className="mt-2 space-y-1">
                  {files.additionalImages.map((file, index) => (
                    <li
                      key={index}
                      className="text-gray-600 text-[12px] truncate"
                    >
                      {file.name}
                    </li>
                  ))}
                </ul>
                {files.additionalImages.length >= 5 && (
          <p className="text-red-500 text-xs mt-2">You can upload up to 5 images only.</p>
        )}
              </div>
            </div>
          </>
        );
        case 4:
          return (
            <div className="max-h-[440px] overflow-y-scroll project-list-scroll">
              
              <div className="mb-4">
                <h2 className="mb-2 text-[16px]">1. Project Details</h2>
                <div className="bg-[#fafafa] border rounded-[12px] p-4">
                  <div className="grid grid-cols-12">
                    <div className="col-span-5">
                      <div className="flex flex-col">
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Project Name</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.name}</p>
                        </div>
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Email Address</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.email}</p>
                        </div>
                        <div className="">
                          <h2 className="text-[#979797] text-[14px]">Phone Number</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.phoneNumber}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-7 pl-4">
                    <div className="mb-5 flex flex-row gap-[60px]">
                      <div>
                          <h2 className="text-[#979797] text-[14px]">Project Symbol</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.symbol}</p>
                      </div>
                      <div>
                          <h2 className="text-[#979797] text-[14px]">Postal Code</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.postalCode}</p>
                      </div>
                        </div>
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Website Url</h2>
                          <p className="mt-1 text-[14px] truncate text-[#52bbab]">
                    <a href={formData.website} target="_blank" rel="noopener noreferrer" className="text-[#52bbab] hover:underline">
                      {formData.website}
  </a>
</p>
                        </div>
                        <div className="">
                          <h2 className="text-[#979797] text-[14px]">Project Description</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.description}</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <h2 className="mb-2 text-[16px]">2. Financial Details</h2>
                <div className="bg-[#fafafa] border rounded-[12px] p-4">
                  <div className="grid grid-cols-12">
                    <div className="col-span-5">
                      <div className="flex flex-col">
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Required Investment</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.requiredInvestment}</p>
                        </div>
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Percentage To Burn</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.burn}</p>
                        </div>
                        <div className="">
                          <h2 className="text-[#979797] text-[14px]">Min Investment</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.minInvestment}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-7 pl-4">
                    <div className="mb-5 flex flex-row gap-[60px]">
                      <div>
                          <h2 className="text-[#979797] text-[14px]">Lock In Period</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.lockInPeriod}</p>
                      </div>
                      <div>
                          <h2 className="text-[#979797] text-[14px]">Max Token Supply</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.maxSupply}</p>
                      </div>
                        </div>
                        <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">Currencies Accepted</h2>
                            <ul className="mt-1 text-[14px] text-[#52bbab]">
                              {selectedCurrencies.length > 0 ? (
                                selectedCurrencies.map((currency) => (
                                  <li key={currency.id}>{currency.name}</li> // Display the name of the selected currencies
                                ))
                              ) : (
                                <li>No currencies selected</li> // Fallback message when no currency is selected
                              )}
                            </ul>

                        </div>
                       
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <h2 className="mb-2 text-[16px]">3. Visual & Market Details</h2>
                <div className="bg-[#fafafa] border rounded-[12px] p-4">
                  <div className="grid grid-cols-12">
                    <div className="col-span-5">
                      <div className="flex flex-col">
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Project Type </h2>
                          <p className="mt-1 text-[14px] truncate">{getProjectType(formData?.projectType)?.name}</p>
                        </div>
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Project Stage</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.projectStage}</p>
                        </div>
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Geographic Market</h2>
                          <p className="mt-1 text-[14px] truncate">{formData.geographicMarket}</p>
                        </div>
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Project Icon</h2>
                          <p className="mt-1 text-[14px] truncate">{files?.projectLogo?.name} </p>
                        </div>
                       
                      </div>
                    </div>
                    <div className="col-span-7 pl-4">
                    <div className="mb-5 flex flex-row gap-[60px]">
                      <div>
                          <h2 className="text-[#979797] text-[14px]">Balance Sheet</h2>
                          <p className="mt-1 text-[14px] truncate">{files.balanceSheet?.name}</p>
                      </div>
                     
                        </div>
                        <div className="mb-5">
                          <h2 className="text-[#979797] text-[14px]">Other Documents</h2>
                          <p className="mt-1 text-[14px] truncate">{files?.otherDocument?.length + " file selected"}</p>
                        </div>
                        <div className="mb-5">
                        <h2 className="text-[#979797] text-[14px]">Additional Images </h2>
                        <p className="mt-1 text-[14px] truncate">{files?.additionalImages?.length + " file selected"}</p>


                       
                        </div>
                       
                    </div>
                  </div>
                </div>
              </div>


              
            </div>
          );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="p-6">
        <h1 className="text-[20px] font-semibold mb-3">Add Project</h1>
        <p className="text-[#a4a4a4]">
          Explore detailed insights into your projects. View key metrics,
          investment status, and progress updates, all in one place to help you
          manage and showcase your green initiatives effectively.
        </p>
        <div className="p-6 bg-[#fff] rounded-[16px] mt-4">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-4">
              <div className="bg-[#fafafa] p-4 rounded-[16px] border h-full">
                <h3 className="font-semibold mb-4">Create Account</h3>
                <ul className="flex flex-col justify-between gap-10">
                  {[
                    "Project Details",
                    "Financial Details",
                    "Visual & Market Details",
                    "Submitted",
                  ].map((label, index) => (
                    <li key={index}>
                      <button
                        // onClick={() =>{setStep(index+1)}}
                        className={`w-full flex items-center text-left p-2 rounded-lg`}
                      >
                        {step >= index + 1 ? ( // Change the condition here
                          <span
                            className={`mr-2 ${
                              step >= index + 1
                                ? "bg-[#52bbab] text-[#fff] flex items-center justify-center rounded-full h-[30px] w-[30px]"
                                : "bg-[#a4a4a4] text-[#cfcfcf] flex items-center justify-center rounded-full h-[30px] w-[30px]"
                            }`}
                          >
                            ✔
                          </span>
                        ) : (
                          <span
                            className={`mr-2 ${
                              step >= index + 1
                                ? "bg-[#52bbab] text-[#fff] flex items-center justify-center rounded-full h-[30px] w-[30px]"
                                : "bg-[#a4a4a4] text-[#cfcfcf] flex items-center justify-center rounded-full h-[30px] w-[30px]"
                            }`}
                          >
                            {index + 1}
                          </span>
                        )}
                        <p
                          className={`${
                            step >= index + 1
                              ? "text-[#52bbab]"
                              : "text-[#a4a4a4]"
                          }`}
                        >
                          {label}
                        </p>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-span-8">
              {renderForm()}
              <div className="flex gap-4 mt-4">
                {step > 1 && (
                  <button
                    onClick={handleBack}
                    className="bg-[#fafafa] border text-black rounded-[8px] px-3 py-[7px] flex items-center gap-2"
                    disabled={loader}
                  >
                    <img className="w-[5px] h-[11px] " src={BackIcon} alt="" />
                    <p>Back</p>
                  </button>
                )}

                <button
                  onClick={handleNext}
                  className="bg-[#52bbab] text-white text-center rounded-[8px] py-[7px] px-6 w-auto"
                  disabled={loader} 
                >
                  {loader ? 'Submitting..' : step === 4 ? 'Submit' : 'Next'}
                </button>

                {isModalOpen && <Successfully closeModal={closeModal} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiStepForm;
