import React, { useState } from "react";
import RightIcon from "../../images/Settings/right.png";
import axios from "axios";
import { apiPaths } from "../../service/apiPaths";
import { useProjectManager } from "./useProjectManager";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";


interface FileUploadResponse {
    files: any[]; // Response structure assuming the file URLs or names are returned
    message:string
  }

interface FormData {
  phone?: string;
  address?: string;
  experience?: number;
  linkedInProfile: string;
  governmentId: string; // Array of file names for government IDs
  referenceContact: string;
  previousProjects: string[];
  website: string;
  certifications:string[];

}

interface Files {
  certifications: File[];
  governmentId: File[];
}

const ProjectManager: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    phone:"",
    address:"" ,
    experience:0 ,
    linkedInProfile: "",
    referenceContact: "",
    previousProjects: [],
    website: "",
    governmentId: "",
    certifications: [],
  });

  const [errorMessages, setErrorMessages] = useState<string[]>([]);



  const user = useSelector((store:any)=>store.user.user)

const {becomeProjectManager , getProjectManagerApplications,  loading , error} = useProjectManager()


  const [files, setFiles] = useState<Files>({
    certifications: [],
    governmentId: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [imageUploadLoader, setImageUploadLoader] = useState(false);
  const[getApplicationLoader , setGetApplicationLoader] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

    //USE EFFECT TO FETCH DATA FROM SESSION STORAGE 
    useEffect(()=>{
      const savedData = sessionStorage.getItem('formData');
      // const sessionSavedStep = sessionStorage.get
      if(savedData){
        setFormData(JSON.parse(savedData))
      }
    },[])
  
    // USE EFFECT HOOK TO STORE DATA IN SESSION STORAGE
    useEffect(()=>{
      sessionStorage.setItem("formData",JSON.stringify(formData))
    },[formData])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name === "previousProjects") {
      const projectsArray = value ? value.split(',').map(item => item.trim()) : [];
      
      setFormData(prev => ({
        ...prev,
        previousProjects: projectsArray
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: name === "phone" || name === "experience" ? Number(value) : value,
      }));
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, key: keyof Files) => {
    const fileList = e.target.files;
    if (!fileList) return;

    setFiles((prev) => ({
      ...prev,
      [key]: [...prev[key], ...Array.from(fileList)], // Ensuring previous files aren't overwritten
    }));
  };
  const validateForm = () => {
    let errors: string[] = [];



    const phoneRegex = /^[0-9]{10}$/;
    if ((formData?.phone)?.toString().length!==10 ||  !phoneRegex.test(formData.phone.toString())) {
      console.log(formData.phone?.length)
      toast.error("Enter A Valid Phone Number")
      errors.push("Phone number must be 10 digits.");
      return  false

    }

    // const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+$/;
    const linkedInRegex = /^https:\/\/.*$/

    ;
    if (formData.linkedInProfile ==="" ||  !linkedInRegex.test(formData.linkedInProfile)) {
      console.log("hi")
      toast.error("Enter a valid url starting with https")
      errors.push("Enter a valid url starting with https")
      return  false


    }

    const websiteRegex = /^https:\/\/.*$/

    if (formData.website==="" ||  !websiteRegex.test(formData.website)) {
      toast.error("Enter a valid website url starting with https")
      errors.push("Enter a valid url starting with https")
      return  false



    }

    if (files.governmentId.length === 0) {
      toast.error("Please Select Government Id ")
      errors.push("Please Select Government Id")
      return  false


     
    }

    if (files.certifications.length === 0) {
      toast.error("Please Select Certifications")
      errors.push("Please Select Certifications")
      return  false

     
    }

    setErrorMessages(errors);
    console.log(errors.length)
    return errors.length === 0;
  };

  const uploadFiles = async () => {
    if (!validateForm()) {
      return; 
    }

    setImageUploadLoader(true);
    try {
      let updatedFormData = { ...formData };

      if (files.governmentId.length > 0) {
        let formDataInstance = new FormData();
        files.governmentId.forEach((file) => formDataInstance.append("files", file));

        try {
          const response = await axios.post<FileUploadResponse>(
            "http://localhost:81/" + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          updatedFormData.governmentId = response.data.files[0];
          console.log(response)
        } catch (error) {
          console.error("Error uploading government IDs:", error);
          throw error;
        }
      }

      if (files.certifications.length > 0) {
        let formDataInstance = new FormData();
        files.certifications.forEach((file) => formDataInstance.append("files", file));

        try {
          const response = await axios.post<FileUploadResponse>(
            "http://localhost:81/" + apiPaths.imageUpload,
            formDataInstance,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          
          updatedFormData.certifications = response.data.files;
          console.log(response)

        } catch (error) {
          console.error("Error uploading certifications:", error);
          throw error;
        }
      }

      return handleSubmit(updatedFormData);
    } catch (error) {
      console.error("Error uploading files:", error);
      throw error;
    } finally {
      setImageUploadLoader(false);
    }
  };
  const checkApplicationStatus = async () => {
    try {
      setGetApplicationLoader(true)
      const response = await getProjectManagerApplications();
      console.log(response)
      console.log(response.success)
      
      if (response.success) {
        setErrorMessage("Application Already Exists");
      } else {
        setErrorMessage(null); 
      }
    } catch (error) {
      console.error("Error fetching application status", error);
      setErrorMessage(null);
    } finally {
      setGetApplicationLoader(false)
    }
  };

  useEffect(() => {

    

    checkApplicationStatus();
  }, []);



  const handleSubmit = async (updatedFormdata:FormData) => {
    console.log(updatedFormdata)
    setIsLoading(true);
    try {
        const response = await becomeProjectManager(updatedFormdata)
        console.log(response)
        if(response){
          toast("Request Sent Successfully");
        }
        checkApplicationStatus()
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };







 



  return (
    <div className="bg-[#fff] rounded-[16px] p-6 ">
      <div className="flex items-center gap-2 font-semibold h-full">
            <Link to="/settings">
              <h1 className="mb-0 text-[20px]">Settings</h1>
            </Link>
            <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />
            <h1 className="mb-0 text-[20px] text-[#52bbab]">Project Manager</h1>
          </div>
      <p className="text-[14px] text-[#9e9b9b]">
        Manage your projects by filling in the required details and uploading necessary documents.
      </p>


      {user && user?.isProjectManager ? (<div className="w-full flex justify-center items-center text-xl h-[20rem]">
        <p>You Already Are A Project Manager.</p>
    </div>) :(
      getApplicationLoader ? (<>
      <div className="w-full  flex justify-center items-center text-xl ">
        <p>Fetching Any Existing Applications...</p>
    </div></>):(

      errorMessage ? <>
      <div className="w-full flex justify-center items-center text-xl h-[20rem]">
        <p> Your application has been received and is now pending approval</p>
    </div></>:( <div className="grid grid-cols-12 gap-x-12 gap-y-4">
      {/* Phone */}
      <div className="col-span-6">
        <p className="mb-2">Phone</p>
        <input
          type="text"
          name="phone"
          placeholder="Enter Phone Number"
          value={formData.phone || ""}
          onChange={handleChange}
          className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
        />
      </div>

      <div className="col-span-6">
        <p className="mb-2">Address</p>
        <input
          type="text"
          name="address"
          value={formData.address || ""}
          onChange={handleChange}
          placeholder="Enter Address"
          className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
        />
      </div>

      {/* Experience */}
      <div className="col-span-6">
        <p className="mb-2">Experience</p>
        <input
          type="number"
          name="experience"
          value={formData.experience || ""}
          onChange={handleChange}
          placeholder="Enter Experience (Can Leave Empty If Zero)"
          className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
        />
      </div>

      {/* LinkedIn Profile */}
      <div className="col-span-6">
        <p className="mb-2">LinkedIn Profile</p>
        <input
          type="text"
          name="linkedInProfile"
          value={formData.linkedInProfile}
          onChange={handleChange}
          placeholder="Enter Your LinkedIn Profile Url"
          className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
        />
      </div>

      {/* Government IDs */}
      <div className="col-span-6">
        <p className="mb-2">Government IDs</p>
        <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
          <input
            type="file"
            multiple
            className="hidden"
            onChange={(e) => handleFileChange(e, "governmentId")}
          />
          <div className="flex justify-between items-center">
            <p className="text-gray-500 text-[14px]">
              {files.governmentId.length > 0
                ? `${files.governmentId.length} files selected`
                : "Upload your government IDs"}
            </p>
          </div>
        </label>
        <ul className="text-gray-600 text-[14px] mt-2">
          {files.governmentId.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      </div>

      {/* Certifications */}
      <div className="col-span-6">
        <p className="mb-2">Certifications</p>
        <label className="block bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border cursor-pointer w-full">
          <input
            type="file"
            multiple
            className="hidden"
            onChange={(e) => handleFileChange(e, "certifications")}
          />
          <div className="flex justify-between items-center">
            <p className="text-gray-500 text-[14px]">
              {files.certifications.length > 0
                ? `${files.certifications.length} files selected`
                : "Upload your certifications"}
            </p>
          </div>
        </label>
        <ul className="text-gray-600 text-[14px] mt-2">
          {files.certifications.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      </div>

      {/* Reference Contact */}
      <div className="col-span-6">
        <p className="mb-2">Reference Contact</p>
        <input
          type="text"
          name="referenceContact"
          placeholder="Enter Any Reference Contact"
          value={formData.referenceContact}
          onChange={handleChange}
          className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
        />
      </div>

      {/* Previous Projects */}
      <div className="col-span-6">
      <p className="mb-2">Previous Projects</p>
      <input
        type="text"
        name="previousProjects"
        // Handle the case where previousProjects might be undefined
        value={Array.isArray(formData.previousProjects) ? formData.previousProjects.join(', ') : ''}
        onChange={handleChange}
        placeholder="Enter projects separated by commas"
        className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
      />
    </div>

      {/* Website */}
      <div className="col-span-6">
        <p className="mb-2">Website</p>
        <input
          type="text"
          name="website"
          value={formData.website}
          onChange={handleChange}
          className="block text-[14px] bg-[#eeeeee] rounded-[12px] px-4 py-[12px] border w-full"
          placeholder="Enter Your Website URL"
        />
      </div>

      <div className="col-span-12 mt-6 flex justify-center">
        <button
          onClick={uploadFiles}
          disabled={isLoading || imageUploadLoader}
          className={`px-6 py-2 rounded-[12px] bg-[#52bbab] text-white font-medium ${isLoading ? "opacity-50" : ""}`}
        >
          {(imageUploadLoader || isLoading) ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>)
     
    )


    )
        
      }

      

      
    </div>
  );
};

export default ProjectManager;
