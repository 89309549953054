import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; 
import downImage from "../../../images/icons/down.png";
import DashboardIcon from "../../../images/icons/dashboard.png";
import Explore1 from "../../../images/Explore/image1.png";
import TrandingButton from "../../../images/Explore/trading.png";
import forwordButton from "../../../images/Explore/forword.png";
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import ProgressBar from "./ProgressBar";
import { useExplore } from "../useExplore";
import { ProjectResponse } from "../../Dashboard/Dashboard.props";
import { dummyData } from "../../Dashboard/Components/dummyData";


const Explore: React.FC = () => {

  const navigate = useNavigate(); 
  const { getProjects } = useExplore();
  const [allProjects, setAllProjects] = useState<ProjectResponse[]>([]);
  const [progress, setProgress] = useState(0);
  const [page,setPage]=useState<number>(1);
  const [limit,setLimit]=useState<number>(4);
  const [search,setSearch] = useState<string>("");
  const [searchInput,setSearchInput] = useState<string>("");
  const [totalPages,setTotalPages] = useState(1);
  const [isLoading,setIsLoading]=useState(true);

  useEffect(()=>{
    async function fetchProjects() {
      const response = await getProjects(search,page,limit);
      if (response && Array.isArray(response.docs)) {
        setAllProjects(response.docs);
        setTotalPages(response.totalPages)
        console.log("DATA FOR PAGE :",page)
        console.log("DATA: ",response.docs)
      } else {
        console.error('Error fetching details, using dummy data');
        // setAllProjects(dummyData);
      }
      setIsLoading(false)
    }
    fetchProjects();
  },[search,page])

  useEffect(() => {
    
    // Simulate a progress update over time
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 10;
        } else {
          clearInterval(interval); // Stop the interval once progress reaches 100
          return 100;
        }
      });
    }, 1000); // Increase progress every 1 second

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  // Fallback to dummy data if projects are not available
  // if(allProjects.length===0){
  //   setAllProjects(dummyData)
  // }

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <>
      <div className="rounded-[20px] bg-white h-full">
        <div className="flex flex-col h-full">
          <div className="p-6 border-b-[1px]">
            {/* Top Section: Heading and Actions */}
            <div className="flex flex-wrap items-center justify-between gap-4 mb-4">
              {/* Left Section: Explore Heading */}
              <h1 className="text-[24px] font-semibold">Explore</h1>

              {/* Right Section: Search Field, Filter, and Dashboard Icon */}
              <div className="flex items-center gap-4">
                {/* Search Field */}
                <div className="flex items-center border border-[#56bbac] rounded-full overflow-hidden">
                  <input
                    type="text"
                    placeholder="Search Projects"
                    value={searchInput} // Bind local state to input
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="px-4 py-2 text-[14px] text-[#4a4a4a] outline-none flex-grow"
                  />
                  <button 
                    onClick={()=>setSearch(searchInput)}
                    className="px-4 py-2 bg-[#56bbac] text-white text-[14px] font-semibold hover:bg-[#479d94]">
                    Search
                  </button>
                </div>

                {/* Filter Button */}
                <div className="h-[40px] w-auto px-4 rounded-[100px] gap-1 bg-[#fafafa] border flex items-center justify-center cursor-pointer hover:bg-[#f0f0f0]">
                  Filter
                  <img className="w-[8px] h-[4px] ml-2" src={downImage} alt="Dropdown" />
                </div>

                {/* Dashboard Icon Button */}
                <div className="h-[40px] w-[40px] rounded-[100px] gap-1 bg-[#fafafa] border flex items-center justify-center cursor-pointer hover:bg-[#f0f0f0]">
                  <img className="h-[18px] w-[18px]" src={DashboardIcon} alt="Dashboard" />
                </div>
              </div>
            </div>

            {/* Bottom Section: Description */}
            <p className="text-[14px] text-[#a4a4a4]">
              Discover groundbreaking green initiatives transforming the world. Our platform enables project owners to list eco-friendly projects on the blockchain, while investors can explore, invest, and receive tokens as proof of their contribution to a sustainable future. Join the movement towards a greener tomorrow by supporting projects that align with your values.
            </p>
          </div>

          <div className="grid grid-cols-12 px-3 py-6">
            {/* Map through allProjects to render each project dynamically */}
            {allProjects &&  allProjects.length > 0 ? allProjects.map((project) => (
              <div key={project._id} className="col-span-6 px-3 mt-4">
                <div className="flex explore-card p-4 h-[165px] rounded-[20px] border bg-[#fafafa]">
                  <div className="img-box w-[200px] ">
                    <img className="w-[100px] h-[132px] rounded-md" src={project.logo || Explore1} alt="Project Image" />
                  </div>
                  <div className="content-box w-full">
                    <div className="flex flex-row justify-between">
                      <div>
                        <h2 className="text-[18px] font-semibold">
                          {project.name}
                        </h2>
                        <p className="text-[12px] text-[#a4a4a4]">
                        {project.description.length > 100
                      ? project.description.slice(0, 95) + "..."
                      : project.description}
                        </p>
                      </div>
                      <img className="h-[27px] w-[80px]" src={TrandingButton} alt="Trending Button" />
                    </div>
                    <div className="flex items-center justify-between mt-1">
                      <p className="text-[12px] text-[#a4a4a4]">Raised: ${project.investmentRaised || 0}K</p>
                      <p className="text-[12px] text-[#a4a4a4]">{project.performance || 0}%</p>
                    </div>
                    <ProgressBar progress={project.performance || 0} height={3} color="#56BBAC" />
                      <h2 className="text-[14px] text-[#56BBAC] font-semibold ">
                        Goal: ${project.requiredInvestment}K
                      </h2>
                    <div className="flex flex-row justify-end">
                      <Link to={`/projectdetails/${project._id}`} className="button bg-[#56BBAC] flex items-center
                       gap-2 w-[90px] h-[32px] justify-center rounded-[100px]">
                        <p className="text-[12px] text-white">Explore</p>
                        <img className="h-[20px] w-[20px]" src={forwordButton} alt="Forward Button" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )): (
              // Display this div when allProjects is empty
              <div className="col-span-12 flex items-center justify-center h-[200px]">
                <p className="text-center text-[18px] text-gray-500 font-semibold">
                  {search ? "No Projects Found For The Given Search Parameter ! " :"No Projects Found !!" }
                </p>
              </div>
            )}
          </div>

          {/* Pagination (optional) */}
          <div className="w-full flex text-center items-center justify-center mt-10 mx-auto">
            <div className="flex gap-2 mb-7 justify-center">
              {/* Previous Button */}
              <button
                disabled={page===1}
                onClick={() => page > 1 && setPage(page - 1)}
                className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
              >
                <img className={"h-[10px] w-[7px]"+
                  (page===1? " opacity-40 ":"")} src={prevButton} alt="Previous" />
              </button>

              {/* Page Buttons */}
              {(() => {
                // Calculate start and end of page numbers to display
                const maxVisibleButtons = 3;
                let startPage = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
                let endPage = Math.min(Number(totalPages), startPage + maxVisibleButtons - 1);

                // Adjust startPage if there are fewer pages at the end
                if (endPage - startPage + 1 < maxVisibleButtons) {
                  startPage = Math.max(1, endPage - maxVisibleButtons + 1);
                }

                return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(
                  (pageNum) => (
                    <button
                      key={pageNum}
                      onClick={() => setPage(pageNum)}
                      className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${
                        page === pageNum ? "bg-[#56BBAC] text-white" : ""
                      }`}
                    >
                      {pageNum}
                    </button>
                  )
                );
              })()}

              {/* Next Button */}
              <button
                onClick={() => page < Number(totalPages) && setPage(page + 1)}
                className="h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full"
              >
                <img className={"h-[10px] w-[7px]"+
                  (page===Number(totalPages)? " opacity-40 ":"")} src={nextButton} alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Explore;
