import React, { useEffect, useState } from 'react';
import ProgressBar from './ProgressBar';
import downloadImage from '../../../images/icons/download.png';
import RightTick from '../../../images/icons/tick.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useExplore } from '../useExplore';
import { ProjectResponse } from '../../Dashboard/Dashboard.props';
import toast from 'react-hot-toast';
import ApiService from '../../../service/ApiService';
import { apiPaths } from '../../../service/apiPaths';
import Web3 from 'web3'
import { useSelector } from 'react-redux';
import { Loader } from 'lucide-react'; 



declare global {
  interface Window {
    ethereum: any; 
    reload: () => void;

  }
}

const ExploreDetails = ({investor=false }) => {
  const { id } = useParams<{ id: string }>();
  const { getProjectDetailsById } = useExplore();
  const [projectData, setProjectData] = useState<ProjectResponse | null>(null);
  const [isLoading,setIsLoading]=useState(true);
  const [amount, setAmount] = useState('');
  const [tokens, setTokens] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const user = useSelector((store:any)=>store.user.user)
  const navigate = useNavigate()
  const [isDeploying, setIsDeploying] = useState(false);
  const [deploymentStep, setDeploymentStep] = useState('');
  const [DOBvisibility,setDOBvisibility]= useState(true)

  


  const tokenPrice = 10; // Assume 1 token = $10 (or change as needed)
  interface RawTransaction {
    to: string;
    data: string;
    gas: string;
    gasPrice: string;
    value: string;
    privateKey: string; // Only if backend returns a private key for signing
  }
  


  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value) || 0;
    setAmount(e.target.value);
    setTokens(value / tokenPrice); // Calculate tokens dynamically
  };

  const handleInvestClick = () => {
    console.log(12)
    setShowModal(true); // Open modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close modal
    setAmount('');
    setTokens(0);
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCurrency(e.target.value);
  };

  async function getProjectDetails() {
    const response = await getProjectDetailsById(String(id));
    if (response) {
      setProjectData(response);
    } else {
      toast.error('Error fetching project details');
      console.error('Error fetching details');
    }
    setIsLoading(false)
  }

  useEffect(() => {
   
    getProjectDetails();
  }, [id]);

  if (!projectData) {
    return <p>Loading...</p>;
  } 

  console.log(projectData.userId)
  console.log(user._id)

  //DUMMY DATA 
  const dummyCompliance=[
    'Clear Terms and conditions for investor',
    'Compliance with local and international environmental laws',
    'Adherence to blockchain and cryptocurrency regulations'
  ]

  async function deployHandler(): Promise<void> {
    setIsDeploying(true);
    setDeploymentStep('Preparing deployment data...');
    
    try {
      const deploymentData = {
        name: projectData?.name,
        symbol: projectData?.symbol,
        uri: projectData?.website,
        maxSupply: projectData?.maxSupply,
        initialOwner: '0x2957dB47a166BA53446CB77864b467A253f3D573',
        walletAddress: '0x2957dB47a166BA53446CB77864b467A253f3D573',
        lockInPeriod: projectData?.lockInPeriod,
      };

      setDeploymentStep('Requesting transaction data...');
      let rawTransaction: any = await ApiService({
        method: 'POST',
        endpoint: apiPaths.deployOnBlockChain,
        ledgerApi: true,
        data: deploymentData,
      });
      
      rawTransaction = rawTransaction.response;

      if (!window.ethereum) {
        throw new Error('MetaMask is not installed');
      }
      console.log(rawTransaction)

      setDeploymentStep('Connecting to MetaMask...');
      const web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();
      const senderAddress = accounts[0];

      setDeploymentStep('Waiting for transaction confirmation...');
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [rawTransaction],
      });
      console.log(txHash)
      

      setDeploymentStep('Confirming deployment...');
      const confirmationResponse = await ApiService({
        method: 'POST',
        endpoint: apiPaths.confirmDeploy,
        ledgerApi:true ,

        data: { 
          transactionHash: txHash,  
          projectId: id,
          walletAddress: senderAddress ,
        },

      });

      console.log(confirmationResponse)

      if (confirmationResponse.success) {
        toast.success('Project Deployed Successfully'); 
        // getProjectDetails()
        setDOBvisibility(false)
        // window.location.reload()
      } else {
        toast.error('Please Try Again');
      }
    } catch (err) {
      console.error('Error in deployment flow:', err);
      toast.error('Deployment failed. Please try again.');
    } finally {
      setIsDeploying(false);
      setDeploymentStep('');
    }
  }






  if(projectData.compliance && projectData.compliance?.length<=1 ){
    setProjectData({...projectData,compliance:dummyCompliance})
  }

  const dummyPhase=[
    {phaseName:'Phase 1',phaseDescription :' Research and Development Research and Development Research and Development Research and Development'},
    {phaseName:'Phase 2',phaseDescription :' Research and Development Research and Development Research and Development Research and Development'},
    {phaseName:'Phase 3',phaseDescription :' Research and Development Research and DevelopmentResearch and DevelopmentResearch and DevelopmentResearch and Development'}
  ]
  if(projectData.projectPhases && projectData.projectPhases?.length<=1 ){
    setProjectData({...projectData,projectPhases:dummyPhase})
  }

  /////////////////////////////////////////////////////////////////

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <div className="rounded-[20px] bg-white h-full p-6">

      <div className="explore-details">
        <div className="grid grid-cols-12">
          {/* Left Section */}
          <div className="col-span-6">
          <div className="col-span-6 flex items-center justify-between">
            <div>
              <h1 className="text-[24px] font-semibold">{projectData.name}</h1>
              <h2 className="text-[16px] my-3">{projectData.category.name}</h2>
            </div>
            <div className="flex items-center gap-2">
             {projectData?.userId === user._id && projectData.approvalStatus==='approved' && DOBvisibility &&  <button className="mb-12 text-[12px] py-2 px-3 bg-[#52bbab] text-black rounded-full hover:bg-[#429a91]" onClick = {()=>{
            deployHandler() }}>
                Deploy on Blockchain
              </button>}
              <button className=" mb-12 text-[12px] py-2 px-3 bg-[#eef9f7] text-black border border-[#52bbab] rounded-full hover:bg-[#52bbab] hover:text-white" >
                View Documentation
              </button>
            </div>
          </div>
            <div className="flex items-center justify-between my-1">
              <p className="text-[14px] text-[#a4a4a4]">
                Raised: ${projectData.investmentRaised || 0}K
              </p>
              <p className="text-[14px] text-[#a4a4a4]">
                {projectData.performance || 0}%
              </p>
            </div>
            <ProgressBar
              progress={projectData.performance || 0}
              height={6}
              color="#56BBAC"
            />
            <h5 className="text-[20px] mt-1">
              Goal: ${projectData.requiredInvestment}K
            </h5>
            <p className="text-[#6c6c6c]">
              ESTIMATED CO2 IMPACT: {projectData.burn || 'N/A'} TONNES
            </p>
            <p className="mt-4 text-[#6c6c6c] text-[14px] text-justify">
              {projectData.description}
            </p>
          </div>
          {/* Right Section */}
          <div className="col-span-6 lg:px-6">
            <div className="flex flex-col">
              <img
                className="max-w-full h-auto rounded-lg"
                src={projectData.logo || ''}
                alt={projectData.name}
              />
              {projectData.images && (
                <div className="flex items-center justify-center mt-3 gap-3">
                  {projectData.images.slice(Number(projectData.images.length>=4)).map((img, idx) => (
                    <img
                      key={idx}
                      className="max-w-[160px] h-[90px] rounded-md"
                      src={img}
                      alt={`Project Image ${idx + 1}`}
                    />
                  ))}
                </div>
              )}
              {investor && projectData?.approvalStatus==='deployed' && projectData.userId!==user._id &&   <button className='ml-11 mt-5 w-10/12 py-3 text-xl bg-[#52bbab] text-white rounded-full' onClick={handleInvestClick}>Become An Investor</button>}
            </div>
            
          </div>
        </div>
        <h1 className="text-[22px] font-semibold mt-4">Project Phases</h1>
        <div className="grid grid-cols-12 mt-3">
          {projectData.projectPhases &&
            projectData.projectPhases.map((phase, idx) => (
              <div className="col-span-4 px-2 " key={idx}>
                <div className="explore-cards bg-[#f5f5f5] h-[10rem] border rounded-[16px] p-3">
                  <h3 className="font-semibold">{phase.phaseName || 'N/A'}</h3>
                  <p className="text-[#3d3636] text-[13px]">
                    {phase.phaseDescription || 'No description provided'}
                  </p>
                </div>
              </div>
            ))}
        </div>
        {projectData.auditReports && (
          <div className="audit-report border border-dashed border-[#52bbab] flex items-center justify-between h-[62px] px-4 mt-4 rounded-[10px] bg-[#eef9f7]">
            <p className="text-[#52bbab] font-semibold">Audit Reports</p>
            <div className="flex items-center gap-3">
              <img className="w-[20px]" src={downloadImage} alt="Download" />
              <p className="text-[#52bbab]">Download Report</p>
            </div>
          </div>
        )}
        {projectData.compliance && (
          <div className="Legal-Regulatory mt-7">
            <h1 className="text-[22px] font-semibold">
              Legal and Regulatory Compliance
            </h1>
            <div className="px-1 mt-6 pb-10">
              {projectData.compliance.map((item, idx) => (
                <div className="flex items-center gap-4 mt-4" key={idx}>
                  <img
                    className="w-[30px] h-[30px]"
                    src={RightTick}
                    alt="Compliance"
                  />
                  <p className="text-[16px]">{item}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>


      {isDeploying && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[400px]">
            <div className="flex flex-col items-center">
              <Loader className="w-12 h-12 animate-spin text-[#52bbab] mb-4" />
              <h2 className="text-xl font-semibold mb-4">Deploying Project</h2>
              <p className="text-gray-600 text-center mb-2">{deploymentStep}</p>
              <p className="text-sm text-gray-500 text-center">Please don't close this window</p>
            </div>
          </div>
        </div>
      )}


      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
            <h2 className="text-xl font-semibold mb-4" >Become an Investor</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium">Select Currency</label>
              <select
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                className="mt-1 block w-full border rounded-md p-2"
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="ETH">ETH</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Amount</label>
              <input
                type="number"
                value={amount}
                onChange={handleAmountChange}
                className="mt-1 block w-full border rounded-md p-2"
                placeholder="Enter amount"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Tokens</label>
              <input
                type="number"
                value={tokens.toFixed(2)}
                disabled
                className="mt-1 block w-full border rounded-md p-2 bg-gray-100"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-[#52bbab] text-white py-2 px-4 rounded-lg hover:bg-[#429a91]"
                onClick={() => navigate("/stripe-payment")}
              >
                Confirm
              </button>
              <button
                className="text-red-500 py-2 px-4 rounded-lg hover:bg-gray-100"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExploreDetails;
