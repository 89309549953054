import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

interface GraphProps {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
    }[];
  };
  options?: any;
}

const LineChart: React.FC<GraphProps> = ({ data, options }) => {
  if (!data || !data.labels || !data.datasets) {
    return <div>No data available</div>;
  }

  return (
    <>
      <Line data={data} options={options} />
    </>
  );
};

export default LineChart;
