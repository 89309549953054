import { useState } from "react";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import { Wallet } from "./Wallet.prop";
import axios from "axios";
import toast from "react-hot-toast";

export const useManageWallet = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getWallets = async (): Promise<Wallet[] | null> => {
    setLoading(true);
    setError(null);

    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.wallets,
      });

      if (response.success) {
        return response.response;
      } else {
        return null;
      }
    } catch (error: any) {
      const errorMessage = error.message || "Failed to login";
      setError(errorMessage);
      console.error("Login error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const addWallet = async (wallet: string): Promise<Wallet | null> => {
    try {
      const walletAddress = new URLSearchParams({
        walletAddress: wallet,
      }).toString();

      const response = await axios.post(
        "http://localhost:81/" + apiPaths.wallets,
        walletAddress,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );

      return response.data.response;
    } catch (err) {
      console.log(err);
      toast.error("Error Occured");
      return null;
    }
  };

  const connectWallet = async (wallet: string): Promise<Wallet | null> => {
    try {
      const walletAddress = new URLSearchParams({
        walletAddress: wallet,
      }).toString();

      const response = await axios.patch(
        "http://localhost:81/" + apiPaths.wallets,
        walletAddress,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );

      return response.data.response;
    } catch (err) {
      console.log(err);
      toast.error("Error Occured");
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { getWallets, addWallet, connectWallet, loading, error };
};
