import { useState } from "react";
import { apiPaths } from "../../service/apiPaths";
import ApiService from "../../service/ApiService";
import { ProjectResponse } from "./Dashboard.props";
import toast from "react-hot-toast";

export const useDashboard=()=>{
  const [loading, setLoading] = useState(false);

    const getProjectsByUser = async (id:string|null , page:number , limit:number , project_name:string, purpose:string) => {
        setLoading(true);


        var queryString = "?page="+page + "&limit="+limit 

        if(purpose==='investor'){
          queryString += "&investorId="+id
        }

        if(purpose==='creator'){
          queryString += "&creatorId="+id
        }

        if (project_name){
          queryString += "&search="+project_name
        }
 
        try {
          const response = await ApiService({
            method: "GET",
            endpoint: apiPaths.ListProject+queryString, 
          });
          if (response){
            console.log(response)
            return response.response;
            // if(purpose==='investor'){
            //   return response.response;
            // }
            // else{
            //   return response.response.docs
            // }
            
          }
          else{
            toast.error("Failed to fetch projects");
             return [];
          }
        } catch (error: any) {
            const errorMessage = error.message || "Error while fetching projects";
            toast.error(errorMessage);
            console.error("Project error:", error);
            return [];
        } finally {
          setLoading(false);
        }
      };

      return {getProjectsByUser,loading};

}