import React, { useEffect, useState } from 'react';
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import { useGetInvestorTransactions } from "./useGetTransaction"; 
import { useLocation } from 'react-router-dom';


interface Transaction {
    id: string;
    name: string;
    fiat: string;
    tokens: string;
    updatedAt: string;
    status: 'completed' | 'failed' | 'in-progress';
    projectId:any
}

interface PaginationState {
    currentPage: number;
    totalPages: number;
    totalDocs: number;
    hasNextPage: boolean;
    hasPrevPage: boolean;
}

interface TransactionResponse {
        docs: Transaction[];
  
    totalPages: number;
    totalDocs: number;
    hasNextPage: boolean;
    hasPrevPage: boolean;
}



const Transaction: React.FC = () => {
    const { getTransactions, loading, error , getProjectMangerTransaction } = useGetInvestorTransactions();
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        currentPage: 1,
        totalPages: 1,
        totalDocs: 0,
        hasNextPage: false, 
        hasPrevPage: false
    });

    const location = useLocation();

  
    const projectManager = location.pathname.includes("/investortransaction");


    useEffect(() => {
        async function fetchTransactions() {
            try {
                let resp:TransactionResponse;
                if(projectManager){
                 resp = await getTransactions(pagination.currentPage.toString());
                }else{
                    resp = await getProjectMangerTransaction(pagination.currentPage.toString())
                   
                }
                console.log(resp)
                if (resp) {
                    setTransactions(resp.docs || []);
                    setPagination(prev => ({
                        ...prev,
                        totalPages: resp.totalPages || 1,
                        totalDocs: resp.totalDocs || 0,
                        hasNextPage: resp.hasNextPage || false,
                        hasPrevPage: resp.hasPrevPage || false
                    }));
                }
            } catch (error) {
                console.error('Failed to fetch transactions', error);
            }
        }

        fetchTransactions();
    }, [pagination.currentPage]);

    const getPageNumbers = (): number[] => {
        const totalPages = pagination.totalPages;
        const currentPage = pagination.currentPage;
        const pageNumbers: number[] = [];
        
        if (totalPages <= 4) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= 4) {
                pageNumbers.push(1, 2, 3, 4);
            } else if (currentPage >= totalPages - 3) {
                pageNumbers.push(
                    totalPages - 3,
                    totalPages - 2,
                    totalPages - 1,
                    totalPages
                );
            } else {
                pageNumbers.push(
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    currentPage + 2
                );
            }
        }
        
        return pageNumbers;
    };

    const handlePageChange = (pageNumber: number): void => {
        setPagination(prev => ({ ...prev, currentPage: pageNumber }));
    };

    const handleNextPage = (): void => {
        if (pagination.hasNextPage) {
            setPagination(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
        }
    };

    const handlePrevPage = (): void => {
        if (pagination.hasPrevPage) {
            setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
        }
    };

    const getStatusClass = (status: Transaction['status']): string => {
        switch (status) {
            case "completed":
                return "bg-green-100 text-[#52bbab] border border-[#52bbab]";
            case "failed":
                return "bg-red-100 text-[#ff0707] border border-[#fe6969]";
            case "in-progress":
                return "bg-yellow-100 text-[#ff9900] border border-[#fec165]";
            default:
                return "bg-gray-100 text-[#52bbab] border border-[#52bbab]";
        }
    };

    const formatDate = (date: string): string => {
        const transactionDate = new Date(date);
        const options: Intl.DateTimeFormatOptions = { 
            day: "2-digit", 
            month: "short", 
            year: "numeric" 
        };
        return transactionDate.toLocaleDateString("en-GB", options);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <p>Loading transactions...</p>
            </div>
        );
    }

    // if (error) {
    //     return (
    //         <div className="flex justify-center items-center h-125">
    //             <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
    //                 <p>You Haven't made Any Transactions yet !</p>
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div className="bg-[#fff] p-4 rounded-[16px]">
            <h1 className="text-[20px] font-semibold">Transactions</h1>
            <p className="text-[14px] text-[#9e9b9b] mt-2">
                View and track all your transactions in one place. Monitor
                investments, token transfers, and transaction history for complete
                transparency and control over your activities.
            </p>

            {transactions && transactions.length >0 ? (
                <div className="bg-white border rounded-lg overflow-hidden mt-4">
                <h1 className="ml-3 my-3 font-semibold">Transactions</h1>
                <div className="overflow-x-auto">
                    <table className="min-w-full shadow-sm">
                        <thead>
                            <tr className="bg-gray-100 text-gray-600 text-sm uppercase">
                                <th className="py-3 px-6 text-left">Sr. No.</th>
                                <th className="py-3 px-6 text-left">Name</th>
                                <th className="py-3 px-6 text-left">Amount</th>
                                <th className="py-3 px-6 text-left">Tokens</th>
                                <th className="py-3 px-6 text-left">Timestamp</th>
                                <th className="py-3 px-6 text-left">Status</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                            {transactions.map((transaction, index) => (
                                <tr key={transaction.id} className="border-b border-gray-200 hover:bg-gray-50">
                                    <td className="py-3 px-6">
                                        {((pagination.currentPage - 1) * 4) + index + 1}
                                    </td>
                                    <td className="py-3 px-6">{transaction.projectId[0]?.name}</td>
                                    <td className="py-3 px-6">{transaction.fiat}</td>
                                    <td className="py-3 px-6">{transaction.tokens}</td>
                                    <td className="py-3 px-6">{formatDate(transaction.updatedAt)}</td>
                                    <td className="py-3 px-6">
                                        <span className={`py-1 px-2 rounded-[4px] text-xs font-semibold ${getStatusClass(transaction.status)}`}>
                                            {transaction.status}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            ):(
                <div className='w-full h-[16rem] flex justify-center items-center'>
                    <p className='text-lg text-center'>No Transactions Found.</p>
                </div>
            )}
            

     <div className="flex w-full justify-center mx-auto gap-2 mt-6 mb-1">
    <button 
        onClick={handlePrevPage}
        disabled={!pagination.hasPrevPage}
        className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasPrevPage ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
        <img className="h-[10px] w-[7px]" src={prevButton} alt="Previous" />
    </button>

    {getPageNumbers().map((pageNumber, index) => (
        <button
            key={index}
            onClick={() => handlePageChange(pageNumber)}
            className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${
                pagination.currentPage === pageNumber 
                    ? 'bg-[#56BBAC] text-white' 
                    : ''
            }`}
        >
            {pageNumber}
        </button>
    ))}

    <button 
        onClick={handleNextPage}
        disabled={!pagination.hasNextPage}
        className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasNextPage ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
        <img className="h-[10px] w-[7px]" src={nextButton} alt="Next" />
    </button>
</div>
        </div>
    );
};

export default Transaction;