/**
 * API paths for various CRUD operations
 */
export const apiPaths: { [key: string]: string } = {
  //Auth API list
  Login: 'login',
  Signup:'signup',
  ForgotPassword:"forgot-password",
  VerifyOtp:"verify-otp",
  CreateProject:"api/v1/projects" ,
  ResendOtp:"resend-otp" ,
  Profile:"api/v1/profile",
  ListProject:"api/v1/projects",
  getProjects:"api/v1/projects" ,
  refreshToken:"refresh-token",
  checkTokens:"check-tokens",
  sendOtp:"send-otp" ,
  SetPassword:"set-password" ,
  changePassword:"change-password" ,


  getActiveInvestors:"api/v1/investors/active" ,
  getInvestorTransactions:"api/v1/transactions/investor" ,
  getProjectManagerTransactions:"api/v1/transactions/project-manager" ,


  subscribeNotification:"api/v1/subscribe-notifications" ,

  timeZonePreferences:"api/v1/profile/preferences" ,

  wallets:"api/v1/wallets" ,
  imageUpload:"api/v1/uplode" ,
  becomeProjectManager:"api/v1/project-managers/user/application" ,
  

  Logout:"logout",

  paymentIntent:'api/v1/stripe/payment-intent',

 



  //ledger
  deployOnBlockChain :"api/v1/factory/deploy-project-manager" ,
  confirmDeploy:'api/v1/confirm/deploy'
};      
